.hsPageWrapper {
  padding-bottom: 10px;
}

.hsContainer {
  margin: 16px;
  .hsDetails {
    padding: 0px 16px;
    text-align: left;
    background-color: white;
    margin: 0 15px;
    height: 100%;
    border-radius: 24px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04), 0px 4px 24px rgba(0, 0, 0, 0.064);

    h3 {
      padding: 10px 0px 0px;
      margin: 0;
      font-family: 'Open Sans';
      font-weight: 700;
      font-style: normal;
      font-size: 17px;
      line-height: 22px;
      text-transform: uppercase;
    }

    p {
      font-family: 'Open Sans';
      font-weight: 400;
      font-style: normal;
      font-size: 13px;
      line-height: 17px;
      color: #989898;
    }
  }
}

.hsSubWrapper {
  padding: 0 20px 20px;
}

.hsHurdleCount {
  align-items: center;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 18px;
  color: #898989;
  padding: 18px 0 0;
  margin-bottom: -8px;
}

.hsOverflow {
  // border-bottom: 1px solid #e4e4e4;
  p {
    margin-top: 0;
  }
}

.hsOverflowAuto {
  height: auto;
}

.hsOverflow::-webkit-scrollbar {
  display: none;
}

.hsSection {
  display: flex;
  justify-content: space-between;
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
}
.premiumLock {
  background-color: #E4E4E4;
  border-radius: 8px;
  height: 26px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
}
.premiumPlus {
  display: flex;
  justify-content: center;
  margin: 5px 0;
}
