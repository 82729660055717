@media screen and (max-width: 850px) {
  .borderRadiusPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;

    .borderRadiusPage__title {
      position: relative;
      height: 170px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        color: maroon;
      }
    }

    .borderRadiusPage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 6fr;
      grid-row-gap: 5em;
      grid-gap: 4vw;
      top: 25px;
      width: 100%;
      height: auto;

      .borderRadiusPage__size {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 2em;
      }

      .borderRadiusPage__boxContainer {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 2em;
        .boxContainer1 {
          .box1 {
            position: relative;
            background: gray;
            border-radius: 18px;
            width: 100%;
            height: 100%;
          }
        }
        .boxContainer2 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-gap: 1em;
          .box2 {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            background: gray;
            border-radius: 14px;
            width: 100%;
            height: 100%;
          }
        }

        .boxContainer3 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          grid-gap: 1em;
          .box3 {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            background: gray;
            border-radius: 24px;
            width: 100%;
            height: 100%;
          }
        }

        .boxContainer4 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 1em;
          .box4 {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            background: gray;
            border-radius: 24px;
            width: 100%;
            height: 100%;
          }
        }

        .boxContainer5 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          grid-gap: 1em;
          .box5 {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            background: gray;
            border-radius: 32px;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

@media (min-width: 850px) {
  .borderRadiusPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;

    .borderRadiusPage__title {
      position: relative;
      height: 170px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        color: maroon;
      }
    }

    .borderRadiusPage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 6fr;
      grid-row-gap: 5em;
      grid-gap: 4vw;
      top: 25px;
      width: 100%;
      height: auto;

      .borderRadiusPage__size {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 2em;
        h2 {
          width: 100%;
          height: 150px;
        }
      }

      .borderRadiusPage__boxContainer {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 2em;
        width: 100%;

        .boxContainer1 {
          width: 100%;
          height: 150px;
          .box1 {
            position: relative;
            background: gray;
            border-radius: 18px;
            width: 100%;
            height: 100%;
          }
        }
        .boxContainer2 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-gap: 1em;
          width: 100%;
          height: 150px;
          .box2 {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            background: gray;
            border-radius: 14px;
            width: 100%;
            height: 100%;
          }
        }

        .boxContainer3 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          grid-gap: 1em;
          width: 100%;
          height: 150px;
          .box3 {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            background: gray;
            border-radius: 24px;
            width: 100%;
            height: 100%;
          }
        }

        .boxContainer4 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 1em;
          width: 100%;
          height: 150px;
          .box4 {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            background: gray;
            border-radius: 24px;
            width: 100%;
            height: 100%;
          }
        }

        .boxContainer5 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          grid-gap: 1em;
          width: 100%;
          height: 150px;
          .box5 {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            background: gray;
            border-radius: 32px;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
