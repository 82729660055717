.signupPageWrapper {
  position: relative;

  .toaster-wrapper {
    position: absolute;
    top: 0;
    left: 0;
  }
  .signup-form-wrapper {
    background-color: blue;
  }
}
.separator {
  color: #595959;
}
