@media screen and (max-width: 850px) {
  .buttonsStylePage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .buttonsStylePage__title {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
      height: 170px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        text-decoration: none;
        color: maroon !important;
        a {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: center;
          justify-items: center;
          align-content: center;
          align-items: center;
          color: rgb(53, 5, 5);
        }
      }
    }
    .buttonsStylePage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      width: 100%;
      .buttonsStylePage__buttons1 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;
        row-gap: 25px;
        top: 50px;
        height: auto;
        padding: 25px 0px;
        width: 100%;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
      }

      .buttonsStylePage__buttons2 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;
        row-gap: 25px;
        top: 100px;
        height: auto;
        padding: 25px 0px;
        width: 100%;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);

        .buttonStylePage__pagination {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-items: center;
          align-items: center;
          align-content: center;
          justify-content: center;
          width: 50%;

          .MuiPagination {
            .MuiPagination-ul {
              position: relative;
              display: grid;
              grid-template-columns: 1fr;
              width: 100%;
              background: rgb(182, 181, 181) !important;
              flex-wrap: nowrap;
              li {
                &:first-child {
                  flex-basis: 100%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  > button::after {
                    margin-left: 10px;
                    content: 'previous';
                  }
                }
                &:last-child {
                  flex-basis: 100%;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  > button::before {
                    margin-right: 10px;
                    content: 'next';
                  }
                }
              }
            }
          }
        }
      }

      .buttonStylePage__buttons3 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;
        row-gap: 25px;
        top: 150px;
        height: auto;
        padding: 25px 0px;
        width: 100%;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
      }
    }
  }
}

@media (min-width: 850px) {
  .buttonsStylePage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .buttonsStylePage__title {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
      height: 170px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        text-decoration: none;
        color: maroon !important;
        a {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: center;
          justify-items: center;
          align-content: center;
          align-items: center;
          color: rgb(53, 5, 5);
        }
      }
    }
    .buttonsStylePage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      width: 100%;
      .buttonsStylePage__buttons1 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items: center;
        row-gap: 25px;
        top: 50px;
        height: auto;
        padding: 25px 0px;
        width: 100%;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
      }

      .buttonsStylePage__buttons2 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;
        row-gap: 25px;
        top: 100px;
        height: auto;
        padding: 25px 0px;
        width: 100%;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);

        .buttonStylePage__pagination {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-items: center;
          align-items: center;
          align-content: center;
          justify-content: center;
          width: 50%;

          .MuiPagination {
            .MuiPagination-ul {
              width: 100%;
              background: rgb(182, 181, 181) !important;
              flex-wrap: nowrap;
              li {
                &:first-child {
                  flex-basis: 100%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  > button::after {
                    margin-left: 10px;
                    content: 'previous';
                  }
                }
                &:last-child {
                  flex-basis: 100%;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  > button::before {
                    margin-right: 10px;
                    content: 'next';
                  }
                }
              }
            }
          }
        }
      }

      .buttonStylePage__buttons3 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;
        row-gap: 25px;
        top: 150px;
        height: auto;
        padding: 25px 0px;
        width: 100%;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
      }
    }
  }
}
