.hurdleConsensusPageWrapper {
  // position: absolute;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  justify-content: center;
  align-content: center;
  background: #fff;

  .hurdleConsensusPage__header {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    width: 100%;
    background: white;

    .header__breadcrumbs {
      .breadcrumbs {
        position: relative;
        margin-top: 10px;
        margin-left: 15px;
        padding: 0px 5px;
        letter-spacing: 0px;
        .breadcrumbs__firstDegree {
          background: none;
          color: #808080;
          font-family: 'Open Sans';
          font-weight: 700;
          font-size: 9px;
          line-height: 11px;
          cursor: pointer;
          span {
            padding: 0 !important;
          }
        }

        .breadcrumbs__secondDegree {
          background: none;
          color: #276ef1;
          font-family: 'Open Sans';
          font-weight: 700;
          font-size: 9px;
          line-height: 11px;
          cursor: pointer;
          span {
            padding: 0 !important;
          }
        }
      }
    }

    .header__title {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      top: 0px;
      width: 100%;
      justify-items: flex-start;
      h1 {
        font-family: 'Open Sans';
        font-weight: 600;
        font-style: normal;
        font-size: 26px;
        line-height: 31px;
        color: #595959;
        padding: 0px 20px;
      }

      p {
        font-family: 'Open Sans';
        font-weight: 400;
        font-style: normal;
        font-size: 11px;
        line-height: 13px;
        color: #595959;
        padding: 0px 20px;
        text-align: start;
        margin: 0px 0px 10px 0px;
        padding-bottom: 15px;
      }
    }
  }

  .hurdleConsensusPage__content {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    top: 20px;
    background: white;
    min-height: 100vh;

    form {
      background: #fff;
      // padding: 0 8px;
    }

    .formContainer {
      position: absolute;
      display: grid;
      grid-template-columns: 1fr;
      justify-content: center;
      width: 100%;
      height: auto;
      text-align: left;
      font-weight: 600;
      font-size: 17px;
      line-height: 20px;
      color: #595959;

      .questionnaire__Section1 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        height: auto;
        background: white;
        text-align: left;
        a {
          text-decoration: underline;
        }

        .content__initialQuestion {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          top: -20px;
          justify-content: center;
          justify-items: center;
          width: 100%;
          text-align: left;
          a {
            text-decoration: underline;
          }
          .initialQuestion {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            justify-content: center;
            justify-items: center;
            width: 100%;
            text-align: left;
            a {
              text-decoration: underline;
            }
            h3 {
              justify-self: flex-start;
              font-family: 'Open Sans';
              font-weight: 600;
              font-style: normal;
              font-size: 17px;
              line-height: 20px;
              color: #595959;
              text-align: start;
              padding: 30px 3% 10px 3%;
              span {
                display: inline;
                text-decoration: underline;
                color: #276ef1;
                cursor: pointer;
              }
            }
          }
          .content__redirect {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            justify-content: center;
            align-items: center;
            align-content: center;
            background: black, 36%;
            border: 1px solid #bcbcbc;
            width: 95%;
            height: 123px;
            box-sizing: border-box;
            border-radius: 8px;
            h4 {
              font-family: 'Open Sans';
              font-weight: 400;
              font-style: normal;
              font-size: 14px;
              line-height: 17px;
              color: #989898;
              margin: 0px 0px;
              padding: 0px 0px;
            }
            span {
              margin: 0px 0px;
              padding: 0px 0px;
              cursor: pointer;
              h4 {
                font-family: 'Open Sans';
                font-weight: 600;
                font-style: normal;
                font-size: 14px;
                line-height: 17px;
                color: #276ef1;
                margin: 0px 0px;
                padding: 0px 0px;
              }
            }
          }
          .initialAnswerSelected {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            justify-content: center;
            justify-items: center;
            top: 0px;
            width: 95%;
            background: #a4c2f9;
            border: 1px solid #bcbcbc;
            height: 123px;
            box-sizing: border-box;
            border-radius: 8px;
            padding: 0px 0px;
            margin: 0px 0px;
            a {
              text-decoration: underline;
            }

            .userDetails {
              position: relative;
              display: grid;
              width: 100%;
              grid-template-columns: 1fr 5fr 1fr;
              justify-items: center;
              align-content: center;
              top: 0px;
              padding: 0px 15px;
              img {
                position: relative;
                display: grid;
              }
              .name {
                position: relative;
                display: grid;
                grid-template-columns: 1fr;
                width: 100%;
                align-items: flex-start;
                justify-content: flex-start;
                justify-items: flex-start;
                align-content: center;
                h4 {
                  position: relative;
                  padding: 0px;
                  margin: 0px 0px;
                  font-family: 'Open Sans';
                  font-weight: 400;
                  font-style: normal;
                  font-size: 11px;
                  list-style: 13px;
                  color: white;
                }
              }
              .star {
                position: relative;
                display: grid;
                z-index: 4000;
                width: 25px;
                justify-items: center;
                cursor: pointer;
              }
            }
            .answer {
              position: relative;
              display: grid;
              justify-items: flex-start;
              justify-content: flex-start;
              width: 100%;
              top: 10px;

              h3 {
                justify-self: flex-start;
                align-self: flex-start;
                font-family: 'Open Sans';
                font-weight: 400;
                font-style: normal;
                font-size: 17px;
                list-style: 24px;
                margin: 0px 0px;
                padding: 0px 20px;
                color: white;
                text-align: start;
              }
            }
          }
          .initialAnswerTextFieldContainer {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            justify-content: center;
            justify-items: center;
            top: 20px;
            width: 100%;

            .textField {
              position: relative;
              display: grid;
              grid-template-columns: 1fr;
              align-self: center;
              width: 95%;
              padding: 0px;
            }
          }
        }

        .content__gender {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-content: center;
          justify-items: center;
          width: 100%;
          h3 {
            justify-self: flex-start;
            font-family: 'Open Sans';
            font-weight: 600;
            font-style: normal;
            font-size: 17px;
            line-height: 20px;
            color: #595959;
            padding: 0px 15px;
            text-align: start;
          }

          .content__redirect {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            justify-content: center;
            align-items: center;
            align-content: center;
            background: black, 36%;
            border: 1px solid #bcbcbc;
            width: 95%;
            height: 123px;
            box-sizing: border-box;
            border-radius: 8px;
            h4 {
              font-family: 'Open Sans';
              font-weight: 400;
              font-style: normal;
              font-size: 14px;
              line-height: 17px;
              color: #989898;
              margin: 0px 0px;
              padding: 0px 0px;
            }
            span {
              margin: 0px 0px;
              padding: 0px 0px;
              cursor: pointer;
              h4 {
                font-family: 'Open Sans';
                font-weight: 600;
                font-style: normal;
                font-size: 14px;
                line-height: 17px;
                color: #276ef1;
                margin: 0px 0px;
                padding: 0px 0px;
              }
            }
          }

          .genderContainerSelected {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            width: 95vw;
            height: 123px;
            justify-self: center;
            background: #a4c2f9;
            border: 1px solid #bcbcbc;
            box-sizing: border-box;
            border-radius: 8px;
            .genderDetails {
              position: absolute;
              display: grid;
              width: 100%;
              grid-template-columns: 1fr 5fr 1fr;
              align-items: center;
              top: 15px;
              padding: 0px 15px;
              img {
                position: relative;
                display: grid;
              }
              .name {
                position: relative;
                display: grid;
                grid-template-columns: 1fr;
                align-items: center;
                justify-content: flex-start;
                justify-items: flex-start;
                align-content: center;
                h4 {
                  position: relative;
                  padding: 0px;
                  margin: 0px 0px;
                  font-family: 'Open Sans';
                  font-weight: 400;
                  font-style: normal;
                  font-size: 11px;
                  list-style: 13px;
                  color: white;
                }
              }
              .star {
                position: relative;
                display: grid;
                z-index: 4000;
                width: 25px;
                justify-items: center;
                cursor: pointer;
              }
            }
            .gender {
              position: relative;
              display: grid;
              justify-items: flex-start;
              top: 60px;
              h3 {
                font-family: 'Open Sans';
                font-weight: 400;
                font-style: normal;
                font-size: 17px;
                list-style: 24px;
                margin: 0px 0px;
                color: white;
              }
            }
          }
          .radioButtons {
            position: relative;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-column-gap: 20%;
            justify-content: center;
            align-content: center;
            padding: 10px 0px;
            label {
              span {
                font-family: 'Open Sans';
                font-weight: 400;
                font-style: normal;
                font-size: 14px;
                line-height: 17px;
                color: black;
              }
            }
          }
        }

        .content__age {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          top: -20px;
          justify-content: center;
          justify-items: center;
          width: 100%;

          h3 {
            justify-self: flex-start;
            font-family: 'Open Sans';
            font-weight: 600;
            font-style: normal;
            font-size: 17px;
            line-height: 20px;
            color: #595959;
            text-align: start;
            padding: 30px 3% 10px 3%;
            span {
              display: inline;
              text-decoration: underline;
              color: #276ef1;
              cursor: pointer;
            }
          }

          .content__redirect {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            justify-content: center;
            align-items: center;
            align-content: center;
            background: black, 36%;
            border: 1px solid #bcbcbc;
            width: 95%;
            height: 123px;
            box-sizing: border-box;
            border-radius: 8px;
            h4 {
              font-family: 'Open Sans';
              font-weight: 400;
              font-style: normal;
              font-size: 14px;
              line-height: 17px;
              color: #989898;
              margin: 0px 0px;
              padding: 0px 0px;
            }
            span {
              margin: 0px 0px;
              padding: 0px 0px;
              cursor: pointer;
              h4 {
                font-family: 'Open Sans';
                font-weight: 600;
                font-style: normal;
                font-size: 14px;
                line-height: 17px;
                color: #276ef1;
                margin: 0px 0px;
                padding: 0px 0px;
              }
            }
          }

          .ageContainerSelected {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            justify-content: center;
            justify-items: center;
            top: 0px;
            width: 95%;
            background: #a4c2f9;
            border: 1px solid #bcbcbc;
            height: 123px;
            box-sizing: border-box;
            border-radius: 8px;
            padding: 0px 0px;
            margin: 0px 0px;

            .ageDetails {
              position: relative;
              display: grid;
              width: 100%;
              grid-template-columns: 1fr 5fr 1fr;
              justify-items: center;
              align-content: center;
              top: 0px;
              padding: 0px 15px;
              img {
                position: relative;
                display: grid;
              }
              .name {
                position: relative;
                display: grid;
                grid-template-columns: 1fr;
                width: 100%;
                align-items: flex-start;
                justify-content: flex-start;
                justify-items: flex-start;
                align-content: center;
                h4 {
                  position: relative;
                  padding: 0px;
                  margin: 0px 0px;
                  font-family: 'Open Sans';
                  font-weight: 400;
                  font-style: normal;
                  font-size: 11px;
                  list-style: 13px;
                  color: white;
                }
              }
              .star {
                position: relative;
                display: grid;
                z-index: 4000;
                width: 25px;
                justify-items: center;
                cursor: pointer;
              }
            }
            .age {
              position: relative;
              display: grid;
              justify-items: flex-start;
              justify-content: flex-start;
              width: 100%;
              top: 10px;

              h3 {
                justify-self: flex-start;
                align-self: flex-start;
                font-family: 'Open Sans';
                font-weight: 400;
                font-style: normal;
                font-size: 17px;
                list-style: 24px;
                margin: 0px 0px;
                padding: 0px 20px;
                color: white;
                text-align: start;
              }
            }
          }

          .ageTextFieldContainer {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            justify-content: center;
            justify-items: center;
            top: 20px;
            width: 100%;

            .textField {
              position: relative;
              display: grid;
              grid-template-columns: 1fr;
              align-self: center;
              width: 95%;
              padding: 0px;
            }
          }
        }
      }

      .questionnaire__Section2 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        height: 100%;
        background: white;

        .content__questionnaire {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          width: 100%;
          height: auto;
          top: 50px;
          background: white;
          justify-content: center;
          justify-items: center;
          border-top: 2px dashed #bcbcbc;

          .questionnaire__header {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            width: 100%;
            top: 30px;
            h3 {
              position: relative;
              font-family: 'Open Sans';
              font-weight: 600;
              font-style: normal;
              font-size: 17px;
              list-style: 20px;
              margin: 0px 0px;
              color: #595959;
              text-align: start;
              padding: 0px 15px;
            }
            p {
              position: relative;
              top: 5px;
              font-family: 'Open Sans';
              font-weight: 400;
              font-style: normal;
              font-size: 14px;
              list-style: 17px;
              margin: 0px 0px;
              color: #595959;
              text-align: start;
              padding: 0px 15px;
            }
          }
          .question__list {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            width: 100%;
            height: auto;
            top: 50px;
            justify-content: center;
            justify-items: center;

            .content__question1 {
              position: relative;
              display: grid;
              grid-template-columns: 1fr;
              justify-content: center;
              justify-items: center;
              width: 100%;
              height: auto;
              background: white;
              .question1__title {
                position: relative;
                display: grid;
                grid-template-columns: 1fr;
                width: 100%;
                justify-content: flex-start;
                justify-items: flex-start;

                h3 {
                  justify-self: flex-start;
                  font-family: 'Open Sans';
                  font-weight: 600;
                  font-style: normal;
                  font-size: 17px;
                  line-height: 20px;
                  color: #595959;
                  text-align: start;
                  padding: 0px 15px;
                }
              }
              .content__redirect {
                position: relative;
                display: grid;
                grid-template-columns: 1fr;
                justify-content: center;
                align-items: center;
                align-content: center;
                background: black, 36%;
                border: 1px solid #bcbcbc;
                width: 95%;
                height: 123px;
                box-sizing: border-box;
                border-radius: 8px;
                h4 {
                  font-family: 'Open Sans';
                  font-weight: 400;
                  font-style: normal;
                  font-size: 14px;
                  line-height: 17px;
                  color: #989898;
                  margin: 0px 0px;
                  padding: 0px 0px;
                }
                span {
                  margin: 0px 0px;
                  padding: 0px 0px;
                  cursor: pointer;
                  h4 {
                    font-family: 'Open Sans';
                    font-weight: 600;
                    font-style: normal;
                    font-size: 14px;
                    line-height: 17px;
                    color: #276ef1;
                    margin: 0px 0px;
                    padding: 0px 0px;
                  }
                }
              }
              .question1AnswerSelected {
                position: relative;
                display: grid;
                grid-template-columns: 1fr;
                justify-content: center;
                justify-items: center;
                top: 0px;
                width: 95%;
                background: #a4c2f9;
                border: 1px solid #bcbcbc;
                height: 123px;
                box-sizing: border-box;
                border-radius: 8px;
                padding: 0px 0px;
                margin: 0px 0px;

                .userDetails {
                  position: relative;
                  display: grid;
                  width: 100%;
                  grid-template-columns: 1fr 5fr 1fr;
                  justify-items: center;
                  align-content: center;
                  top: 0px;
                  padding: 0px 15px;
                  img {
                    position: relative;
                    display: grid;
                  }
                  .name {
                    position: relative;
                    display: grid;
                    grid-template-columns: 1fr;
                    width: 100%;
                    align-items: flex-start;
                    justify-content: flex-start;
                    justify-items: flex-start;
                    align-content: center;
                    h4 {
                      position: relative;
                      padding: 0px;
                      margin: 0px 0px;
                      font-family: 'Open Sans';
                      font-weight: 400;
                      font-style: normal;
                      font-size: 11px;
                      list-style: 13px;
                      color: white;
                    }
                  }
                  .star {
                    position: relative;
                    display: grid;
                    z-index: 4000;
                    width: 25px;
                    justify-items: center;
                    cursor: pointer;
                  }
                }
                .answer {
                  position: relative;
                  display: grid;
                  justify-items: flex-start;
                  justify-content: flex-start;
                  width: 100%;
                  top: 10px;

                  h3 {
                    justify-self: flex-start;
                    align-self: flex-start;
                    font-family: 'Open Sans';
                    font-weight: 400;
                    font-style: normal;
                    font-size: 17px;
                    list-style: 24px;
                    margin: 0px 0px;
                    padding: 0px 20px;
                    color: white;
                    text-align: start;
                  }
                }
              }
              .question1TextFieldContainer {
                position: relative;
                display: grid;
                grid-template-columns: 1fr;
                justify-content: center;
                justify-items: center;
                top: 20px;
                width: 100%;

                .textField {
                  position: relative;
                  display: grid;
                  grid-template-columns: 1fr;
                  align-self: center;
                  width: 95%;
                  padding: 0px;
                }
              }
            }
            .content__question2 {
              position: relative;
              display: grid;
              grid-template-columns: 1fr;
              justify-content: center;
              justify-items: center;
              width: 100%;
              top: 20px;
              height: auto;
              background: white;
              .question2__title {
                position: relative;
                display: grid;
                grid-template-columns: 1fr;
                width: 100%;
                justify-content: flex-start;
                justify-items: flex-start;

                h3 {
                  justify-self: flex-start;
                  font-family: 'Open Sans';
                  font-weight: 600;
                  font-style: normal;
                  font-size: 17px;
                  line-height: 20px;
                  color: #595959;
                  text-align: start;
                  padding: 0px 15px;
                }
              }
              .content__redirect {
                position: relative;
                display: grid;
                grid-template-columns: 1fr;
                justify-content: center;
                align-items: center;
                align-content: center;
                background: black, 36%;
                border: 1px solid #bcbcbc;
                width: 95%;
                height: 123px;
                box-sizing: border-box;
                border-radius: 8px;
                h4 {
                  font-family: 'Open Sans';
                  font-weight: 400;
                  font-style: normal;
                  font-size: 14px;
                  line-height: 17px;
                  color: #989898;
                  margin: 0px 0px;
                  padding: 0px 0px;
                }
                span {
                  margin: 0px 0px;
                  padding: 0px 0px;
                  cursor: pointer;
                  h4 {
                    font-family: 'Open Sans';
                    font-weight: 600;
                    font-style: normal;
                    font-size: 14px;
                    line-height: 17px;
                    color: #276ef1;
                    margin: 0px 0px;
                    padding: 0px 0px;
                  }
                }
              }
              .question2AnswerSelected {
                position: relative;
                display: grid;
                grid-template-columns: 1fr;
                justify-content: center;
                justify-items: center;
                top: 0px;
                width: 95%;
                background: #a4c2f9;
                border: 1px solid #bcbcbc;
                height: 123px;
                box-sizing: border-box;
                border-radius: 8px;
                padding: 0px 0px;
                margin: 0px 0px;

                .userDetails {
                  position: relative;
                  display: grid;
                  width: 100%;
                  grid-template-columns: 1fr 5fr 1fr;
                  justify-items: center;
                  align-content: center;
                  top: 0px;
                  padding: 0px 15px;
                  img {
                    position: relative;
                    display: grid;
                  }
                  .name {
                    position: relative;
                    display: grid;
                    grid-template-columns: 1fr;
                    width: 100%;
                    align-items: flex-start;
                    justify-content: flex-start;
                    justify-items: flex-start;
                    align-content: center;
                    h4 {
                      position: relative;
                      padding: 0px;
                      margin: 0px 0px;
                      font-family: 'Open Sans';
                      font-weight: 400;
                      font-style: normal;
                      font-size: 11px;
                      list-style: 13px;
                      color: white;
                    }
                  }
                  .star {
                    position: relative;
                    display: grid;
                    z-index: 4000;
                    width: 25px;
                    justify-items: center;
                    cursor: pointer;
                  }
                }
                .answer {
                  position: relative;
                  display: grid;
                  justify-items: flex-start;
                  justify-content: flex-start;
                  width: 100%;
                  top: 10px;

                  h3 {
                    justify-self: flex-start;
                    align-self: flex-start;
                    font-family: 'Open Sans';
                    font-weight: 400;
                    font-style: normal;
                    font-size: 17px;
                    list-style: 24px;
                    margin: 0px 0px;
                    padding: 0px 20px;
                    color: white;
                    text-align: start;
                  }
                }
              }
              .question2TextFieldContainer {
                position: relative;
                display: grid;
                grid-template-columns: 1fr;
                justify-content: center;
                justify-items: center;
                top: 20px;
                width: 100%;

                .textField {
                  position: relative;
                  display: grid;
                  grid-template-columns: 1fr;
                  align-self: center;
                  width: 95%;
                  padding: 0px;
                }
              }
            }
            .content__question3 {
              position: relative;
              display: grid;
              grid-template-columns: 1fr;
              justify-content: center;
              justify-items: center;
              width: 100%;
              top: 40px;
              height: auto;
              background: white;
              .question3__title {
                position: relative;
                display: grid;
                grid-template-columns: 1fr;
                width: 100%;
                justify-content: flex-start;
                justify-items: flex-start;

                h3 {
                  justify-self: flex-start;
                  font-family: 'Open Sans';
                  font-weight: 600;
                  font-style: normal;
                  font-size: 17px;
                  line-height: 20px;
                  color: #595959;
                  text-align: start;
                  padding: 0px 15px;
                }
              }
              .content__redirect {
                position: relative;
                display: grid;
                grid-template-columns: 1fr;
                justify-content: center;
                align-items: center;
                align-content: center;
                background: black, 36%;
                border: 1px solid #bcbcbc;
                width: 95%;
                height: 123px;
                box-sizing: border-box;
                border-radius: 8px;
                h4 {
                  font-family: 'Open Sans';
                  font-weight: 400;
                  font-style: normal;
                  font-size: 14px;
                  line-height: 17px;
                  color: #989898;
                  margin: 0px 0px;
                  padding: 0px 0px;
                }
                span {
                  margin: 0px 0px;
                  padding: 0px 0px;
                  cursor: pointer;
                  h4 {
                    font-family: 'Open Sans';
                    font-weight: 600;
                    font-style: normal;
                    font-size: 14px;
                    line-height: 17px;
                    color: #276ef1;
                    margin: 0px 0px;
                    padding: 0px 0px;
                  }
                }
              }
              .question3AnswerSelected {
                position: relative;
                display: grid;
                grid-template-columns: 1fr;
                justify-content: center;
                justify-items: center;
                top: 0px;
                width: 95%;
                background: #a4c2f9;
                border: 1px solid #bcbcbc;
                height: 123px;
                box-sizing: border-box;
                border-radius: 8px;
                padding: 0px 0px;
                margin: 0px 0px;

                .userDetails {
                  position: relative;
                  display: grid;
                  width: 100%;
                  grid-template-columns: 1fr 5fr 1fr;
                  justify-items: center;
                  align-content: center;
                  top: 0px;
                  padding: 0px 15px;
                  img {
                    position: relative;
                    display: grid;
                  }
                  .name {
                    position: relative;
                    display: grid;
                    grid-template-columns: 1fr;
                    width: 100%;
                    align-items: flex-start;
                    justify-content: flex-start;
                    justify-items: flex-start;
                    align-content: center;
                    h4 {
                      position: relative;
                      padding: 0px;
                      margin: 0px 0px;
                      font-family: 'Open Sans';
                      font-weight: 400;
                      font-style: normal;
                      font-size: 11px;
                      list-style: 13px;
                      color: white;
                    }
                  }
                  .star {
                    position: relative;
                    display: grid;
                    z-index: 4000;
                    width: 25px;
                    justify-items: center;
                    cursor: pointer;
                  }
                }
                .answer {
                  position: relative;
                  display: grid;
                  justify-items: flex-start;
                  justify-content: flex-start;
                  width: 100%;
                  top: 10px;

                  h3 {
                    justify-self: flex-start;
                    align-self: flex-start;
                    font-family: 'Open Sans';
                    font-weight: 400;
                    font-style: normal;
                    font-size: 17px;
                    list-style: 24px;
                    margin: 0px 0px;
                    padding: 0px 20px;
                    color: white;
                    text-align: start;
                  }
                }
              }
              .question3TextFieldContainer {
                position: relative;
                display: grid;
                grid-template-columns: 1fr;
                justify-content: center;
                justify-items: center;
                top: 20px;
                width: 100%;

                .textField {
                  position: relative;
                  display: grid;
                  grid-template-columns: 1fr;
                  align-self: center;
                  width: 95%;
                  padding: 0px;
                }
              }
            }
            .content__question4 {
              position: relative;
              display: grid;
              grid-template-columns: 1fr;
              justify-content: center;
              justify-items: center;
              width: 100%;
              top: 60px;
              height: auto;
              background: white;
              margin-bottom: 50px;
              padding-bottom: 150px;
              .question4__title {
                position: relative;
                display: grid;
                grid-template-columns: 1fr;
                width: 100%;
                justify-content: flex-start;
                justify-items: flex-start;

                h3 {
                  justify-self: flex-start;
                  font-family: 'Open Sans';
                  font-weight: 600;
                  font-style: normal;
                  font-size: 17px;
                  line-height: 20px;
                  color: #595959;
                  text-align: start;
                  padding: 0px 15px;
                }
              }
              .content__redirect {
                position: relative;
                display: grid;
                grid-template-columns: 1fr;
                justify-content: center;
                align-items: center;
                align-content: center;
                background: black, 36%;
                border: 1px solid #bcbcbc;
                width: 95%;
                height: 123px;
                box-sizing: border-box;
                border-radius: 8px;
                h4 {
                  font-family: 'Open Sans';
                  font-weight: 400;
                  font-style: normal;
                  font-size: 14px;
                  line-height: 17px;
                  color: #989898;
                  margin: 0px 0px;
                  padding: 0px 0px;
                }
                span {
                  margin: 0px 0px;
                  padding: 0px 0px;
                  cursor: pointer;
                  h4 {
                    font-family: 'Open Sans';
                    font-weight: 600;
                    font-style: normal;
                    font-size: 14px;
                    line-height: 17px;
                    color: #276ef1;
                    margin: 0px 0px;
                    padding: 0px 0px;
                  }
                }
              }
              .question4AnswerSelected {
                position: relative;
                display: grid;
                grid-template-columns: 1fr;
                justify-content: center;
                justify-items: center;
                top: 0px;
                width: 95%;
                background: #a4c2f9;
                border: 1px solid #bcbcbc;
                height: 123px;
                box-sizing: border-box;
                border-radius: 8px;
                padding: 0px 0px;
                margin: 0px 0px;

                .userDetails {
                  position: relative;
                  display: grid;
                  width: 100%;
                  grid-template-columns: 1fr 5fr 1fr;
                  justify-items: center;
                  align-content: center;
                  top: 0px;
                  padding: 0px 15px;
                  img {
                    position: relative;
                    display: grid;
                  }
                  .name {
                    position: relative;
                    display: grid;
                    grid-template-columns: 1fr;
                    width: 100%;
                    align-items: flex-start;
                    justify-content: flex-start;
                    justify-items: flex-start;
                    align-content: center;
                    h4 {
                      position: relative;
                      padding: 0px;
                      margin: 0px 0px;
                      font-family: 'Open Sans';
                      font-weight: 400;
                      font-style: normal;
                      font-size: 11px;
                      list-style: 13px;
                      color: white;
                    }
                  }
                  .star {
                    position: relative;
                    display: grid;
                    z-index: 4000;
                    width: 25px;
                    justify-items: center;
                    cursor: pointer;
                  }
                }
                .answer {
                  position: relative;
                  display: grid;
                  justify-items: flex-start;
                  justify-content: flex-start;
                  width: 100%;
                  top: 10px;

                  h3 {
                    justify-self: flex-start;
                    align-self: flex-start;
                    font-family: 'Open Sans';
                    font-weight: 400;
                    font-style: normal;
                    font-size: 17px;
                    list-style: 24px;
                    margin: 0px 0px;
                    padding: 0px 20px;
                    color: white;
                    text-align: start;
                  }
                }
              }
              .question4TextFieldContainer {
                position: relative;
                display: grid;
                grid-template-columns: 1fr;
                justify-content: center;
                justify-items: center;
                top: 20px;
                width: 100%;

                .textField {
                  position: relative;
                  display: grid;
                  grid-template-columns: 1fr;
                  align-self: center;
                  width: 95%;
                  padding: 0px;
                }
              }
            }
          }
        }
      }
      .submitButton__section {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        justify-items: center;
        margin: 0px 0px 50px 0px;
        top: 20px;
        .submitButton {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          width: 90%;
        }
      }
    }
  }
}
