.hurdlePageWrapper {
  background-color: #fff;
  height: 100vh;
}

.hurdleDetails {
  padding: 16px;
  text-align: left;
  color: #fff;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(38.63deg, #1F58C1 35.99%, #276EF1 100%);
  margin-top: -65px;
  padding-top: 65px;
  padding-bottom: 70px;

  h2 {
    padding: 0;
    margin: 0;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 31px;
    color: #fff;
  }

  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 25px;
    text-transform: uppercase;
    color: #fff;
    margin: 0;
  }

  p {
    font-size: 13px;
    line-height: 16px;
    color: #fff;
  }
}

.hurdleDetailsContainer {
  width: 100%;
}

.subStopsWrapper {
  min-height: 100vh;
  padding: 0 20px 20px;
  display: flex;
  flex-direction: column;
  background: #fff;
  margin-top: -50px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

.hurdleBackLink {
  color: #fff !important;
}