.lpPageWrapper {
  background-color: #F8F8F8; //#e4e4e4;
  min-height: 100vh;
}

.lpDetails {
  padding: 16px;
  text-align: left;
  background-color: white;
  display: flex;
  justify-content: space-between;
  margin: 10px 10px;
  border-radius: 8px;
  height: 120px;

  h5 {
    padding: 10px 0px;
    margin: 0;
    font-family: 'Open Sans';
    font-style: normal;
    // font-size: 26px;
    line-height: 31px;
    color: #595959;
  }

  h3 {
    padding: 10px 0px;
    margin: 0;
    font-style: normal;
    font-size: 22px;
    font-family: 'Open Sans';
    line-height: 25px;
    text-transform: uppercase;
    color: #276ef1;
  }

  p {
    font-size: 11px;
    line-height: 16px;
    color: #989898;
  }
}

.lpSubWrapper {
  padding: 0 20px 20px;
}

.appHeader {
  background: #f8f8f8;
  padding: 54px 16px 11px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.appName {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  display: flex;
  position: relative;

  color: #595959;
}

.menuIcon {
  color: #595959;
}

.headerBottomSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 16px;
}

.appLogo {
  width: 61px;
  height: 43px;
}

.bannerText {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  color: #595959;
}

