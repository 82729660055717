@media screen and (max-width: 850px) {
  .selectionControlsPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .selectionControlsPage__title {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
      height: 170px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        text-decoration: none;
        color: maroon !important;
        a {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: center;
          justify-items: center;
          align-content: center;
          align-items: center;
          color: rgb(53, 5, 5);
        }
      }
    }

    .selectionControlsPage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center;
      .selectionControlsPage__radioButtons {
        .selectionControlsPage__radioButtons__section1 {
          padding: 15px;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
        }
        .selectionControlsPage__radioButtons__section2 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
          align-items: center;
          padding: 15px;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
        }
      }

      .selectionControlsPage__checkBoxes {
      }
    }
  }
}

@media (min-width: 850px) {
  .selectionControlsPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .selectionControlsPage__title {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
      height: 170px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        text-decoration: none;
        color: maroon !important;
        a {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: center;
          justify-items: center;
          align-content: center;
          align-items: center;
          color: rgb(53, 5, 5);
        }
      }
    }

    .selectionControlsPage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
      .selectionControlsPage__radioButtons {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;
        .selectionControlsPage__radioButtons__section1 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 10vw;
          justify-items: center;
          align-items: center;
          padding: 15px;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);

          .radio__set1 {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
          }
          .radio__set2 {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
          }
        }
        .selectionControlsPage__radioButtons__section2 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
          align-items: center;
          top: 50px;
          padding: 15px;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
        }
      }

      .selectionControlsPage__checkBoxes {
      }
    }
  }
}
