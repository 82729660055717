@media screen and (max-width: 600px) {
  .iconsPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .iconPage__title {
      position: relative;
      height: 240px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        color: maroon;
      }
    }
    .iconPage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      top: 50px;
      grid-gap: 5vw;
      grid-row-gap: 5vw;
      justify-items: center;
    }
  }
}

@media (min-width: 600px) {
  .iconsPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .iconPage__title {
      position: relative;
      height: 240px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        color: maroon;
      }
    }
    .iconPage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      top: 50px;
      grid-gap: 5vw;
      grid-row-gap: 5vw;
      justify-items: center;
    }
  }
}

@media (min-width: 770px) {
  .iconsPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .iconPage__title {
      position: relative;
      height: 200px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        color: maroon;
      }
    }
    .iconPage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      top: 50px;
      grid-gap: 5vw;
      grid-row-gap: 5vw;
      justify-items: center;
    }
  }
}

@media (min-width: 1112px) {
  .iconsPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .iconPage__title {
      position: relative;
      height: 170px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        color: maroon;
      }
    }
    .iconPage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      top: 50px;
      grid-gap: 5vw;
      grid-row-gap: 5vw;
      justify-items: center;
    }
  }
}

@media (min-width: 1366px) {
  .iconsPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .iconPage__title {
      position: relative;
      height: 150px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        color: maroon;
      }
    }
    .iconPage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      top: 50px;
      grid-gap: 5vw;
      grid-row-gap: 5vw;
      justify-items: center;
    }
  }
}
