.projectForm {
  background-color: #fff;
  min-height: 100vh;
}

.projectFormWrapper {
  margin: 17px 16px;
}

.heads {
  color: #595959;
  font-family: 'Open Sans';
  font-style: normal;
  line-height: 20px;
  text-align: 'left';
  margin: 0;
}

.subHeads {
  color: #989898;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px; 
  margin: 0;
  margin-top: 9px;
}

.subFooters {
  color: #989898;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 17px; 
  margin: 7px 3px 0px;
}

.detailsContent {
  color: #989898;
  text-align: left;
  padding: 0 16px;
  .linkContainer {
    margin-top: 16px;
    .link {
      color: #276EF1;
      text-decoration: underline;
      font-size: 14px;
      line-height: 17px;
      cursor: pointer;
    }
  }
}