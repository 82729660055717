.custom-button {
  &.fb-button {
    background-color: #1F58C1;
    // font-weight: 600;
    font-size: 15px;
    font-weight: normal;
    color: #ffffff;
    // height: 72px;
    border-radius: 8px;
    text-transform: none;
    height: 56px;

    &:hover {
      background-color: #3c66c4 !important;
    }

    &:disabled {
      background: #5881db !important;
      color: white;
    }
  }

  &.primary {
    height: 72px;
    border-radius: 45px;
    background: #276ef1;
    border: 1px solid #276ef1;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    text-transform: none;
    text-align: center;
    color: white;

    &:hover {
      background: #276ef1;
    }

    &:disabled {
      background-color: #567edb !important;
      color: white;
    }
  }
}
