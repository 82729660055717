.substop {
  padding: 24px 18px;
  background-color: white;
  margin-top: 20px;
  border-radius: 24px;
  min-height: 24px;
  transition: height ease-in-out 0.25s;
  overflow: hidden;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 24px rgba(0, 0, 0, 0.064);

  .basicInfoRow,
  .detailsRow {
    display: flex;
    justify-content: space-between;
  }

  .detailsRow {
    align-items: flex-end;
    height: 90px;
  }

  p,
  h4 {
    text-align: left;
    margin: 0;
  }

  h4 {
    font-weight: 700;
    font-size: 17px;
    line-height: 25px;
    /* or 147% */

    display: flex;
    align-items: center;
    text-transform: uppercase;

    color: #595959;
  }

  .substopName {
    flex: 65;
    padding: 0 15px 0 0;
  }

  .status {
    flex: 35;
  }

  .duration {
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: #898989;
  }

  .caption {
    font-size: 13px;
    line-height: 16px;
    color: #595959;
    flex: 65;
    overflow: scroll;
    height: 100%;
  }

  .actionLinkWrapper {
    flex: 35;
    text-align: right;

    a {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      text-align: right;
      color: #595959;
      display: inline-block;
      vertical-align: middle;
    }

    svg {
      height: 18px;
      width: 18px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.expanded {
  min-height: 120px;
}

.activeSubStop {
  text-align: right;
  color: #689AF5;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;

  svg {
    height: 18px;
    width: 18px;
    display: inline-block;
    vertical-align: middle;
  }

  span {
    margin-left: 5px;
    font-weight: 600;
    font-size: 14px;
    display: inline-block;
    vertical-align: middle;
  }
}

.notStartedSubStop {
  span {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: right;

    color: #276ef1;
  }
}

.doneSubStop {
  text-align: right;
  color: #689af5;

  svg {
    height: 18px;
    width: 18px;
    display: inline-block;
    vertical-align: middle;
  }

  span {
    margin-left: 5px;
    font-weight: 600;
    font-size: 14px;
    display: inline-block;
    vertical-align: middle;
  }
}
