@media screen and (max-width: 850px) {
  .snackbarPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
    .snackbarPage__title {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
      height: 170px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        text-decoration: none;
        color: maroon !important;
        a {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: center;
          justify-items: center;
          align-content: center;
          align-items: center;
          color: rgb(53, 5, 5);
        }
      }
    }

    .snackbarPage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;

      align-items: center;
      justify-items: center;
      top: 50px;

      .snackbarPage__container__buttonSnackbar {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 2em;
        align-items: stretch;
        justify-items: stretch;
        width: 100%;
      }

      .snackbarPage__container__snackbarAlert {
        position: relative;
        display: grid;
        grid-row-gap: 2em;
        top: 50px;
      }
    }

    .snackbarPage__CustomSnackbars {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      top: 100px;
    }
  }
}

@media screen and (min-width: 850px) {
  .snackbarPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
    .snackbarPage__title {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
      height: 170px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        text-decoration: none;
        color: maroon !important;
        a {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: center;
          justify-items: center;
          align-content: center;
          align-items: center;
          color: rgb(53, 5, 5);
        }
      }
    }

    .snackbarPage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10vw;

      align-items: center;
      justify-items: center;
      top: 50px;

      .snackbarPage__container__buttonSnackbar {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 2em;
        justify-items: center;
        width: 100%;
      }

      .snackbarPage__container__snackbarAlert {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 2em;
        justify-items: center;
        width: 100%;
      }
    }

    .snackbarPage__container__snackbar {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 2em;
      justify-items: center;
      width: 100%;
    }

    .snackbarPage__CustomSnackbars {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      top: 100px;
    }
  }
}
