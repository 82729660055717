.preWorkPageWrapper {
  width: 100%;
  height: 100vh;
  background: #fff;

  .preWorkPage__header {
    width: 100%;
    background: white !important;
    .header__links {
      background: white;
    }
    .header__breadcrumbs {
      .breadcrumbs {
        position: relative;
        margin-top: 10px;
        margin-left: 15px;
        padding: 0px 5px;
        letter-spacing: 0px;
        .breadcrumbs__firstDegree {
          background: none;
          color: #808080;
          font-family: 'Open Sans';
          font-weight: 700;
          font-style: normal;
          font-size: 9px;
          list-style: 11px;
          cursor: pointer;
          span {
            padding: 0 !important;
          }
        }

        .breadcrumbs__secondDegree {
          background: none;
          font-family: 'Open Sans';
          font-weight: 700;
          font-style: normal;
          font-size: 9px;
          list-style: 11px;
          color: #276ef1;
          cursor: pointer;
          span {
            padding: 0 !important;
          }
        }
      }
    }

    .header__title {
      text-align: left;
      h1 {
        font-family: 'Open Sans';
        font-weight: 700;
        font-style: normal;
        font-size: 26px;
        line-height: 31px;
        color: #595959;
        padding: 0px 20px;
      }

      p {
        position: relative;
        top: -10px;
        font-family: 'Open Sans';
        font-weight: 400;
        font-style: normal;
        font-size: 13px;
        line-height: 16px;
        color: #595959;
        padding: 0px 20px;
        text-align: start;
        padding-bottom: 10px;
      }
    }
  }

  .preWorkPage__content {
    padding: 16px;
    background: #fff;
    text-align: left;

    a {
      text-decoration: underline;
    }

    form {
      background: #fff;
      // padding: 0 8px;
    }

    > div {
      margin-bottom: 16px;
    }
  }
}
