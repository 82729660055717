@media screen and (max-width: 600px) {
  .stylesUsagePage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;

    .stylesUsagePage__title {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      width: 100%;
      height: 170px;
      top: 0px;
      left: 0px;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      align-items: center;
      justify-content: center;
    }

    .stylesUsagePage__container {
      .warningHeader {
        h3 {
          font-family: 'Crimson Text';
          color: red;
        }
        h4 {
          font-family: 'Open Sans';
          span {
            font-family: 'Crimson Text';
            color: maroon;
          }
        }
      }

      .stylesUsagePage__typography {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;

        border: 1px solid gray;
        background: rgb(221, 221, 221);
        p {
          span {
            font-weight: bold;
          }
        }
        h2 {
          position: relative;
          display: flex;
          flex-direction: row;
          width: 100%;
          align-items: center;
          align-content: center;
          justify-items: center;
          justify-content: center;
        }
      }

      .stylesUsagePage__colors {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        top: 50px;
        border: 1px solid gray;
        background: rgb(221, 221, 221);
        p {
          span {
            font-weight: bold;
          }
        }
        h2 {
          position: relative;
          display: flex;
          flex-direction: row;
          width: 100%;
          align-items: center;
          align-content: center;
          justify-items: center;
          justify-content: center;
        }

        .brandColors__section {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          border: 1px solid gray;
        }

        .bgColors__section {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          border: 1px solid gray;
        }

        .textColors__section {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          border: 1px solid gray;
        }

        .auxColors__section {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          border: 1px solid gray;
        }

        .labelColors__section {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          border: 1px solid gray;
        }
      }

      .stylesUsagePage__icons {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        top: 100px;
        border: 1px solid gray;
        background: rgb(221, 221, 221);
        p {
          span {
            font-weight: bold;
            a {
              text-decoration: underline;
              color: blue;
            }
          }
        }
        h2 {
          position: relative;
          display: flex;
          flex-direction: row;
          width: 100%;
          align-items: center;
          align-content: center;
          justify-items: center;
          justify-content: center;
        }
      }

      .stylesUsagePage__radius {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        top: 150px;
        border: 1px solid gray;
        background: rgb(221, 221, 221);
        p {
          span {
            font-weight: bold;
          }
          .ni2radius14 {
            position: relative;
            border-radius: 14px;
            background: gray;
            color: white;
            margin: 0px 25px;
          }
        }
        h2 {
          position: relative;
          display: flex;
          flex-direction: row;
          width: 100%;
          align-items: center;
          align-content: center;
          justify-items: center;
          justify-content: center;
        }
      }
    }
  }
}

@media (min-width: 600px) {
  .stylesUsagePage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;

    .stylesUsagePage__title {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      width: 100%;
      height: 170px;
      top: 0px;
      left: 0px;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      align-items: center;
      justify-content: center;
    }

    .stylesUsagePage__container {
      .warningHeader {
        h3 {
          font-family: 'Crimson Text';
          color: red;
        }
        h4 {
          font-family: 'Open Sans';
          span {
            font-family: 'Crimson Text';
            color: maroon;
          }
        }
      }

      .stylesUsagePage__typography {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;

        border: 1px solid gray;
        background: rgb(221, 221, 221);
        p {
          span {
            font-weight: bold;
          }
        }
        h2 {
          position: relative;
          display: flex;
          flex-direction: row;
          width: 100%;
          align-items: center;
          align-content: center;
          justify-items: center;
          justify-content: center;
        }
      }

      .stylesUsagePage__colors {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        top: 50px;
        border: 1px solid gray;
        background: rgb(221, 221, 221);
        p {
          span {
            font-weight: bold;
          }
        }
        h2 {
          position: relative;
          display: flex;
          flex-direction: row;
          width: 100%;
          align-items: center;
          align-content: center;
          justify-items: center;
          justify-content: center;
        }

        .brandColors__section {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          border: 1px solid gray;
        }

        .bgColors__section {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          border: 1px solid gray;
        }

        .textColors__section {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          border: 1px solid gray;
        }

        .auxColors__section {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          border: 1px solid gray;
        }

        .labelColors__section {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          border: 1px solid gray;
        }
      }

      .stylesUsagePage__icons {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        top: 100px;
        border: 1px solid gray;
        background: rgb(221, 221, 221);
        p {
          span {
            font-weight: bold;
            a {
              text-decoration: underline;
              color: blue;
            }
          }
        }
        h2 {
          position: relative;
          display: flex;
          flex-direction: row;
          width: 100%;
          align-items: center;
          align-content: center;
          justify-items: center;
          justify-content: center;
        }
      }

      .stylesUsagePage__radius {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;

        top: 150px;
        border: 1px solid gray;
        background: rgb(221, 221, 221);
        p {
          span {
            font-weight: bold;
          }
          .ni2radius14 {
            position: relative;
            border-radius: 14px;
            background: gray;
            color: white;
            height: 50px;
            margin: 0px 100px;
          }
        }
        h2 {
          position: relative;
          display: flex;
          flex-direction: row;
          width: 100%;
          align-items: center;
          align-content: center;
          justify-items: center;
          justify-content: center;
        }
      }
    }
  }
}

// Typography Utility Styles
.ni2heading {
  font-family: 'Crimson Text' !important;
  font-size: 52px !important;
  line-height: 74.4px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

.ni2h1 {
  font-family: 'Crimson Text' !important;
  font-size: 52px !important;
  line-height: 62px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

.ni2h2 {
  font-family: 'Crimson Text' !important;
  font-size: 40px !important;
  line-height: 48px !important;
  font-style: normal !important;
  font-weight: 600 !important;
}

.ni2h3 {
  font-family: 'Crimson Text' !important;
  font-size: 32px !important;
  line-height: 38px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

.ni2bodyHeading {
  font-family: 'Crimson Text' !important;
  font-size: 26px !important;
  line-height: 46.5px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

.ni2h4 {
  font-family: 'Open Sans' !important;
  font-size: 26px !important;
  line-height: 31px !important;
  font-style: normal !important;
  font-weight: 600 !important;
}

.ni2h5 {
  font-family: 'Open Sans' !important;
  font-size: 21px !important;
  line-height: 25px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

.ni2h6 {
  font-family: 'Open Sans' !important;
  font-size: 17px !important;
  line-height: 208px !important;
  font-style: normal !important;
  font-weight: 600 !important;
}

.ni2body {
  font-family: 'Open Sans' !important;
  font-size: 17px !important;
  line-height: 25px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

.ni2subHeader {
  font-family: 'Open Sans' !important;
  font-size: 14px !important;
  line-height: 17px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

.ni2button {
  font-family: 'Open Sans' !important;
  font-size: 14px !important;
  line-height: 17px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

.ni2caption1 {
  font-family: 'Open Sans' !important;
  font-size: 11px !important;
  line-height: 13px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

.ni2overline {
  font-family: 'Open Sans' !important;
  font-size: 9px !important;
  line-height: 11px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

// Font Color Utility Styles - Branding
.ni2Blue {
  color: #276ef1 !important;
}

.ni2DarkBlue {
  color: #276ef1 !important;
}

.ni2LightBlue {
  color: #689af5 !important;
}

.ni2SubtleBlue {
  color: #e3eaf7 !important;
}

// Background Colors
.ni2WhiteBG {
  background: #ffffff;
  background-color: #ffffff;
}

.ni2LightGrayBG {
  background: #e4e4e4;
  background-color: #e4e4e4;
}

.ni2DarkBG {
  background: #595959;
  background-color: #595959;
}

.ni2DarkerBG {
  background: #1d1d1d;
  background-color: #1d1d1d;
}

// Text Colors

.ni2TextColor {
  color: #595959;
}

.ni2TextGray {
  color: #989898;
}

.ni2LightTextGray {
  color: #bcbcbc;
}

// Auxilliary Colors

.ni2Success {
  background: #43a047;
  background-color: #43a047;
}

.ni2Warning {
  background: #ffa000;
  background-color: #ffa000;
}

.ni2Error {
  background: #b00020;
  background-color: #b00020;
}

.ni2Info {
  background: #017aad;
  background-color: #017aad;
}

// Label Colors

.ni2Success1 {
  background: #34c759;
  background-color: #34c759;
}

.ni2Warning1 {
  background: #5856d6;
  background-color: #5856d6;
}

.ni2Error1 {
  background: #ff9500;
  background-color: #ff9500;
}

.ni2Info1 {
  background: #ff375f;
  background-color: #ff375f;
}

.ni2Success2 {
  background: #af52de;
  background-color: #af52de;
}

.ni2Warning2 {
  background: #ff3b30;
  background-color: #ff3b30;
}

.ni2Error2 {
  background: #5ac8fa;
  background-color: #5ac8fa;
}

.ni2Info2 {
  background: #ffcc00;
  background-color: #ffcc00;
}

// Border Radius

.ni2radius18 {
  border-radius: 18px;
}

.ni2radius14 {
  border-radius: 14px;
}

.ni2radius24 {
  border-radius: 24px;
}

.ni2radius32 {
  border-radius: 32px;
}
