.workout {
  background: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04), 0px 4px 24px rgba(0, 0, 0, 0.064);

  .workout__header {
    display: flex;
    justify-content: space-between;
    h1 {
      font-family: 'Open Sans';
      font-weight: 700;
      font-style: normal;
      font-size: 17px;
      color: #595959;
      margin: 0;
    }
  }

  .workout__content {
    .workout__copyFromApi {
      padding: 10px 100px 10px 0;
      font-family: 'Open Sans';
      font-weight: 400;
      font-style: normal;
      font-size: 13px;
      line-height: 16px;
      color: #595959;
      text-align: left;
      border-bottom: 1px solid #e4e4e4;
    }

    p {
      font-family: 'Open Sans';
      font-weight: 400;
      font-style: normal;
      font-size: 13px;
      line-height: 16px;
      color: #595959;
      text-align: left;
    }
    .workout__button {
      text-align: right;
      padding-top: 5px;
      .ctaButton {
        width: 100%;
        color: #276ef1;

        button {
          padding: 6px 0;
        }

        span {
          text-transform: capitalize;
          font-weight: 600;
        }
      }
    }
  }
}

.tep {
  background: white;
  border-radius: 24px;
  padding: 16px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04), 0px 4px 24px rgba(0, 0, 0, 0.064);

  .tep__header {
    display: flex;
    justify-content: space-between;

    h1 {
      font-family: 'Open Sans';
      font-weight: 700;
      font-style: normal;
      font-size: 17px;
      color: #595959;
      margin: 0;
    }
  }

  .tep__content {
    .tep__copyFromApi {
      padding: 10px 0 20px 0;
      font-family: 'Open Sans';
      font-weight: 400;
      font-style: normal;
      font-size: 13px;
      line-height: 16px;
      color: #595959;
      text-align: left;
      border-bottom: 1px solid #e4e4e4;
    }
    .tep__button {
      text-align: right;
      padding-top: 5px;
      .ctaButton {
        width: 100%;
        color: #276ef1;

        button {
          padding: 6px 0;
        }

        span {
          text-transform: capitalize;
          font-weight: 600;
        }
      }
    }
  }
}

.googleSheets {
  background: white;
  border-radius: 8px;
  .googleSheets__header {
    h1 {
      font-family: 'Open Sans';
      font-weight: 700;
      font-style: normal;
      font-size: 17px;
      line-height: 22px;
      color: #595959;
      margin: 0;
    }
  }

  .googleSheets__content {
    p {
      margin: 0px 100px 0px 0px;
      font-family: 'Open Sans';
      font-weight: 400;
      font-style: normal;
      font-size: 13px;
      line-height: 16px;
      color: #595959;
      text-align: start;
    }
    .googleSheets__button {
      .ctaButton {
        color: #276ef1;
      }
    }
  }
}

.initialQuestionnaire {
  background: white;
  padding-bottom: 30px;

  .initialQuestionnaire__section1 {
    padding: 0px 3%;
    h3 {
      font-family: 'Open Sans';
      font-weight: 700;
      font-style: normal;
      font-size: 17px;
      color: #595959;
      padding-top: 25px;
    }
    p {
      text-align: start;
    }
    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .initialQuestionnaire__section2 {
    padding: 0px 3%;
    h3 {
      font-family: 'Open Sans';
      font-weight: 700;
      font-style: normal;
      font-size: 17px;
      line-height: 20px;
      color: #595959;
    }
    .radioButtons {
      label {
        margin: 0px;
        span {
          font-family: 'Open Sans';
          font-weight: 400;
          font-style: normal;
          font-size: 14px;
          line-height: 17px;
          color: black;
          padding: 0px;
        }
      }
    }
  }

  .submitButton__section {
    .submitButton {
    }
  }
}

.imageResourceWrapper {
  text-align: center;

  img {
    max-width: 100%;
    max-height: 220px;
  }
}
