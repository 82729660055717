.preWorkoutPageWrapper {
  height: 100vh;
  width: 100%;
  background: #fff;

  .preWorkoutPage__header {
    width: 100%;
    background: white;

    .header__breadcrumbs {
      .breadcrumbs {
        margin-top: 10px;
        margin-left: 15px;
        padding: 0px 5px;
        letter-spacing: 0px;
        .breadcrumbs__firstDegree {
          background: none;
          color: #808080;
          font-family: 'Open Sans';
          font-weight: 700;
          font-size: 9px;
          line-height: 11px;
          cursor: pointer;
          span {
            padding: 0 !important;
          }
        }

        .breadcrumbs__secondDegree {
          background: none;
          color: #276ef1;
          font-family: 'Open Sans';
          font-weight: 700;
          font-size: 9px;
          line-height: 11px;
          cursor: pointer;
          span {
            padding: 0 !important;
          }
        }
      }
    }

    .header__title {
      width: 100%;
      text-align: left;

      h1 {
        font-family: 'Open Sans';
        font-weight: 600;
        font-style: normal;
        font-size: 26px;
        line-height: 31px;
        color: #595959;
        padding: 0px 20px;
      }

      p {
        font-family: 'Open Sans';
        font-weight: 400;
        font-style: normal;
        font-size: 11px;
        line-height: 13px;
        color: #595959;
        padding: 0px 20px;
        text-align: start;
        margin: 0px 0px 10px 0px;
        padding-bottom: 15px;
      }
    }
  }

  .preWorkoutPage__content {
    justify-content: center;
    width: 100%;
    min-height: 100px;
    background: #fff;
    
    form {
      background: #fff;
      // padding: 0 8px;
    }

    .questionnaire {
      display: flex;
      flex-direction: column;
      width: 100%;
      background: #fff;
      text-align: left;
      color: #595959;

      a {
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: 376px) {
  .header__breadcrumbs {
    .breadcrumbs {
      margin-top: 10px;
      padding: 0px 5px;
      letter-spacing: 0px;

      .breadcrumbs__firstDegree {
        background: none;
        color: #808080;
        font-family: 'Open Sans';
        font-weight: 700;
        font-size: 9px;
        line-height: 11px;
        cursor: pointer;
        span {
          padding: 0 !important;
        }
      }

      .breadcrumbs__secondDegree {
        background: none;
        color: #276ef1;
        font-family: 'Open Sans';
        font-weight: 700;
        font-size: 9px;
        line-height: 11px;
        cursor: pointer;
        // margin: 5px 0px 0px;
        span {
          padding: 0 !important;
        }
      }
    }
  }
}
