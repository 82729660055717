@media screen and (max-width: 850px) {
  .textfieldStatePage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .textfieldStatePage__title {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
      height: 170px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        text-decoration: none;
        color: maroon !important;
        a {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: center;
          justify-items: center;
          align-content: center;
          align-items: center;
          color: rgb(53, 5, 5);
        }
      }
    }

    .textfieldStatePage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center;

      .textfieldStatePage__section1 {
        position: relative;
        top: 50px;
        .textfieldStatePage__section1__field1 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
          h2 {
            position: relative;
            bottom: 15px;
          }
        }
        .textfieldStatePage__section1__field2 {
          position: relative;
          top: 75px;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
          h2 {
            position: relative;
            bottom: 15px;
          }
        }
        .textfieldStatePage__section1__field3 {
          position: relative;
          top: 150px;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
          h2 {
            position: relative;
            bottom: 15px;
          }
        }
      }

      .textfieldStatePage__section2 {
        position: relative;
        top: 250px;
        .textfieldStatePage__section2__field4 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
          h2 {
            position: relative;
            bottom: 15px;
            color: gray;
          }
        }
        .textfieldStatePage__section2__field5 {
          position: relative;
          top: 25px;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
          h2 {
            position: relative;
            bottom: 15px;
          }
        }
        .textfieldStatePage__section2__field6 {
          position: relative;
          top: 50px;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
          h2 {
            position: relative;
            bottom: 15px;
          }
        }
      }
    }
  }
}

@media (min-width: 850px) {
  .textfieldStatePage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .textfieldStatePage__title {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
      height: 170px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        text-decoration: none;
        color: maroon !important;
        a {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: center;
          justify-items: center;
          align-content: center;
          align-items: center;
          color: rgb(53, 5, 5);
        }
      }
    }

    .textfieldStatePage__container {
      position: relative;
      display: grid;
      grid-template-columns: 2fr 2fr;
      grid-gap: 5vw;
      justify-items: center;

      .textfieldStatePage__section1 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        top: 50px;

        .textfieldStatePage__section1__field1 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
        }
        .textfieldStatePage__section1__field2 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
        }
        .textfieldStatePage__section1__field3 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
        }
      }

      .textfieldStatePage__section2 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        top: 50px;

        .textfieldStatePage__section2__field4 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
        }
        .textfieldStatePage__section2__field5 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
        }
        .textfieldStatePage__section2__field6 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
        }
      }
    }
  }
}

@media (min-width: 1250px) {
  .textfieldStatePage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .textfieldStatePage__title {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
      height: 170px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        text-decoration: none;
        color: maroon !important;
        a {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: center;
          justify-items: center;
          align-content: center;
          align-items: center;
          color: rgb(53, 5, 5);
        }
      }
    }

    .textfieldStatePage__container {
      position: relative;
      display: grid;
      grid-template-columns: 2fr 2fr;
      grid-gap: 15vw;
      justify-items: center;

      .textfieldStatePage__section1 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        top: 50px;

        .textfieldStatePage__section1__field1 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
        }
        .textfieldStatePage__section1__field2 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
        }
        .textfieldStatePage__section1__field3 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
        }
      }

      .textfieldStatePage__section2 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        top: 50px;

        .textfieldStatePage__section2__field4 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
        }
        .textfieldStatePage__section2__field5 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
        }
        .textfieldStatePage__section2__field6 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
        }
      }
    }
  }
}
