.workPageWrapper {
  position: absolute;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  background: #e5e5e5;

  .workPage__header {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    width: 100%;
    background: white;

    .header__breadcrumbs {
      .breadcrumbs {
        position: relative;
        margin-top: 10px;
        margin-left: 15px;
        padding: 0px 5px;
        letter-spacing: 0px;
        cursor: pointer;
        .breadcrumbs__firstDegree {
          background: none;
          color: #808080;
          font-family: 'Open Sans';
          font-weight: 700;
          font-size: 9px;
          line-height: 11px;
          cursor: pointer;
          span {
            padding: 0 !important;
          }
        }

        .breadcrumbs__secondDegree {
          background: none;
          color: #808080;
          font-family: 'Open Sans';
          font-weight: 700;
          font-size: 9px;
          line-height: 11px;
          cursor: pointer;
          span {
            padding: 0 !important;
          }
        }

        .breadcrumbs__thirdDegree {
          background: none;
          color: #276ef1;
          font-family: 'Open Sans';
          font-weight: 700;
          font-size: 9px;
          line-height: 11px;
          cursor: pointer;
          span {
            padding: 0 !important;
          }
        }
      }
    }

    .header__title {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      justify-items: flex-start;
      h1 {
        font-family: 'Open Sans';
        font-weight: 600;
        font-style: normal;
        font-size: 26px;
        line-height: 31px;
        color: #595959;
        padding: 0px 20px;
      }

      p {
        font-family: 'Open Sans';
        font-weight: 400;
        font-style: normal;
        font-size: 11px;
        line-height: 13px;
        color: #595959;
        padding: 0px 20px;
        text-align: start;
        margin: 0px 0px 10px 0px;
        padding-bottom: 15px;
      }
    }
  }

  .workPage__content {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    top: 0px;
    width: 100%;
    height: 680px;
    background: #fff;

    form {
      background: #fff;
      // padding: 0 8px;
    }

    .initialQuestionnaire {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      top: 20px;
      width: 100%;
      height: 474px;
      background: white;
      text-align: left;

      .initialQuestionnaire__section1 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        padding: 0px 3%;

        h3 {
          position: relative;
          height: 20px;
          font-family: 'Open Sans';
          font-weight: 600;
          font-style: normal;
          font-size: 17px;
          line-height: 20px;
          color: #595959;
          text-align: start;
          padding-top: 0px;
          margin: 35px 0px 0px 0px;
        }
        p {
          text-align: start;
        }
        span {
          text-decoration: underline;
          cursor: pointer;
        }
        .initialAnswerTextField {
          position: relative;
          top: -10px;
        }
        a {
          text-decoration: underline;
        }
      }

      .initialQuestionnaire__section2 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        padding: 0px 3%;

        a {
          text-decoration: underline;
        }
        .gender {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          h3 {
            position: relative;
            top: -20px;
            font-family: 'Open Sans';
            font-weight: 600;
            font-style: normal;
            font-size: 17px;
            line-height: 20px;
            color: #595959;
            text-align: start;
            padding: 0px;
            margin: 0px;
          }
          .radioButtons {
            position: relative;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            top: -30px;
            padding: 0px;
            margin: 0px;
            label {
              span {
                font-family: 'Open Sans';
                font-weight: 400;
                font-style: normal;
                font-size: 14px;
                line-height: 17px;
                color: black;
              }
            }
          }
        }

        .age {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          h3 {
            position: relative;
            top: -30px;
            font-family: 'Open Sans';
            font-weight: 600;
            font-style: normal;
            font-size: 17px;
            line-height: 20px;
            color: #595959;
            text-align: start;
            padding: 0px;
            margin: 0px;
          }
          .ageTextField {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            height: 50px;
            top: -30px;
          }
        }
      }
    }

    .questionnaire {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      width: 100%;
      top: 40px;
      height: 926px;
      background: white;
      text-align: left;

      a {
        text-decoration: underline;
      }

      .questionnaire__header {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        height: 100px;
        padding: 0px 3%;
        h3 {
          position: relative;
          margin: 40px 0px 0px 0px;
          font-family: 'Open Sans';
          font-weight: 600;
          font-style: normal;
          font-size: 17px;
          line-height: 20px;
          color: #595959;
          text-align: start;
        }
        p {
          position: relative;
          top: 0px;
          margin: 0px;
          font-family: 'Open Sans';
          font-weight: 400;
          font-style: normal;
          font-size: 14px;
          line-height: 17px;
          color: #595959;
          text-align: start;
        }
      }

      .questions {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        padding: 0px 3%;
        a {
          text-decoration: underline;
        }

        .questions__list {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          top: -40px;
          a {
            text-decoration: underline;
          }

          .nested {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;

            .title {
              position: relative;
              display: grid;
              grid-template-columns: 1fr;
              top: -20px;
            }

            .content {
              position: relative;
              display: grid;
              grid-template-columns: 1fr;
              top: -20px;
            }
          }
        }
      }
    }

    .submitButton__section {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      width: 100%;
      justify-items: center;
      margin: 0px 0px 50px 0px;
      top: -20px;
      .submitButton {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        width: 94%;
      }
    }
  }
}
