@media screen and (max-width: 1112px) {
  .brandColorPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .brandColorPage__title {
      position: relative;
      height: 170px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        color: maroon;
      }
    }

    .brandColorPage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      top: 25px;

      .brandColorPage__row1 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;

        .brandColorPage__row1col1__box1 {
          height: 300px;
          width: 300px;
          background: #276ef1;
        }
        h1 {
          color: #276ef1;
        }

        .brandColorPage__row1col2__box2 {
          height: 300px;
          width: 300px;
          background: #1f58c1;
        }
        h1 {
          color: #1f58c1;
        }
      }

      .brandColorPage__row2 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;

        .brandColorPage__row2col1__box3 {
          height: 300px;
          width: 300px;
          background: #689af5;
        }
        h1 {
          color: #689af5;
        }

        .brandColorPage__row2col2__box4 {
          height: 300px;
          width: 300px;
          background: #e3eaf7;
        }
        h1 {
          color: #e3eaf7;
        }
      }
    }
  }
}
@media (min-width: 1112px) {
  .brandColorPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .brandColorPage__title {
      position: relative;
      height: 170px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        color: maroon;
      }
    }

    .brandColorPage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      top: 25px;

      .brandColorPage__row1 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20vw;
        justify-items: center;

        .brandColorPage__row1col1__box1 {
          height: 300px;
          width: 300px;
          background: #276ef1;
        }
        h1 {
          color: #276ef1;
        }

        .brandColorPage__row1col2__box2 {
          height: 300px;
          width: 300px;
          background: #1f58c1;
        }
        h1 {
          color: #1f58c1;
        }
      }

      .brandColorPage__row2 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20vw;
        justify-items: center;

        .brandColorPage__row2col1__box3 {
          height: 300px;
          width: 300px;
          background: #689af5;
        }
        h1 {
          color: #689af5;
        }

        .brandColorPage__row2col2__box4 {
          height: 300px;
          width: 300px;
          background: #e3eaf7;
        }
        h1 {
          color: #e3eaf7;
        }
      }
    }
  }
}
