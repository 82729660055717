.peerExchangePageWrapper {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  height: auto;
  justify-content: center;
  align-content: center;
  background: #e5e5e5;

  a {
    text-decoration: underline;
  }

  .peerExchangePage__header {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    width: 100%;
    background: white;

    .header__breadcrumbs {
      .breadcrumbs {
        position: relative;
        margin-top: 10px;
        margin-left: 15px;
        padding: 0px 5px;
        letter-spacing: 0px;
        .breadcrumbs__firstDegree {
          background: none;
          color: #808080;
          font-family: 'Open Sans';
          font-weight: 700;
          font-size: 9px;
          line-height: 11px;
          cursor: pointer;
          span {
            padding: 0 !important;
          }
        }

        .breadcrumbs__secondDegree {
          background: none;
          color: #276ef1;
          font-family: 'Open Sans';
          font-weight: 700;
          font-size: 9px;
          line-height: 11px;
          cursor: pointer;
          span {
            padding: 0 !important;
          }
        }
      }
    }

    .header__title {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      top: 0px;
      width: 100%;
      justify-items: flex-start;
      h1 {
        font-family: 'Open Sans';
        font-weight: 600;
        font-style: normal;
        font-size: 26px;
        line-height: 31px;
        color: #595959;
        padding: 0px 20px;
      }

      p {
        font-family: 'Open Sans';
        font-weight: 400;
        font-style: normal;
        font-size: 11px;
        line-height: 13px;
        color: #595959;
        padding: 0px 20px;
        text-align: start;
        margin: 0px 0px 10px 0px;
        padding-bottom: 15px;
      }
    }

    .peerExchangePage__content {
      min-height: 100vh;
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      top: 0px;
      // width: 100%;
      justify-items: flex-start;
      margin: 0px 15px;
      background: #ffffff;

      .content_container {
        height: 171.2px;
        border-radius: 16px;
        padding: 16px;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04),
        0px 4px 24px rgba(0, 0, 0, 0.064);
      }

      a {
        text-decoration: underline;
      }

      form {
        background: #fff;
        // padding: 0 8px;
      }

      h2 {
        font-family: 'Open Sans';
        font-weight: 700;
        font-style: normal;
        font-size: 15px;
        line-height: 25px;
      }

      p {
        font-family: 'Open Sans';
        font-weight: 400;
        font-style: normal;
        font-size: 13px;
        line-height: 16.5px;
        text-align: start;
      }
    }
  }
}
