.contentContainer {
  background-color: #fff; // #689AF5 Revamp
  color: #595959; // #fff
  // height: 200px;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.contentHead {
  font-family: 'Open Sans';
  //max-height: 180px;
  text-align: left;
  margin: 20px 16px;
}

.collab {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contentTitle {
  display: flex;
  justify-content: space-between;
}

.contentText {
  //max-height: 80px;
  // margin-bottom: 20px;
  //overflow-y: auto;
  color: #989898;
  p {
    margin-bottom: 0;
  }
  span {
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
    a {
      color: #276ef1 !important;
    }
  }
}

.collapse {
  p > div > div {
    overflow: hidden;
    position: relative;
    max-height: 100px;
    margin-right: -1em;
    padding-right: 1em;
  }
  p > div > div:before {
    // content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  p > div > div:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white;
  }
}

.titleCollab {
  justify-self: flex-end;
  .contentText {
    max-height: 80px;
    height: auto;
  }
}

.contentHead h2 {
  margin-bottom: 0;
  margin-top: 0;
  // padding-top: 20px;
}