.signin-signup-wrapper {
  // background: url('../../assets/images/skyscraper-bg.png') no-repeat #c8e2f7;
  background-size: cover;
  min-height: 100vh;
  position: relative;

  .loading-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .logo-wrapper {
    // padding: 62px 0;
    margin-bottom: 24px;
    img {
      width: 100%;
    }
  }

  .copyright-section {
    padding-top: 69px;

    p {
      padding: 0;
      margin: 0;
    }

    .copy-text {
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      color: #595959;
    }

    .terms-links {
      a {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        color: #595959;
      }
      span {
        padding: 0 5px;
      }
    }
  }

  .form-row {
    padding: 0 15px 16px;

    &:last-child {
      padding-bottom: 0;
    }

    .forgot-password-link-wrapper {
      text-align: left;
      padding-top: 11px;
      a {
        // text-transform: uppercase;
        // font-weight: bold;
        font-size: 11px;
        line-height: 14px;
        color: #1D1D1D;
      }
    }

    > div,
    button {
      width: 100%;
    }

    &.checkbox-input-wrapper {
      text-align: left;
      > label {
        span,
        a {
          font-size: 14px;
          line-height: 17px;
          color: rgba(0, 0, 0, 0.87);
        }

        a {
          text-decoration: underline;
        }
      }
    }

    &.error {
      * {
        color: red !important;
      }

      .error-message {
        font-size: 12px;
      }
    }
  }

  .loading-wrapper {
    height: 45px;
  }

  .other-options-section {
    > div {
      padding-bottom: 18px;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      /* identical to box height */

      text-align: center;

      a {
        color: #595959;
        font-size: 17px;
        text-decoration: underline;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  .social-media-links {
    padding: 18px 0;
    text-align: center;

    a {
      display: inline-block;
      margin-right: 23px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
