@media screen and (max-width: 850px) {
  .textfieldTypesPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .textfieldTypesPage__title {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
      height: 170px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        text-decoration: none;
        color: maroon !important;
        a {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: center;
          justify-items: center;
          align-content: center;
          align-items: center;
          color: rgb(53, 5, 5);
        }
      }
    }

    .textfieldTypesPage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center;
      top: 50px;
    }
  }
}

@media (min-width: 1112px) {
  .textfieldTypesPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .textfieldTypesPage__title {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
      height: 170px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        text-decoration: none;
        color: maroon !important;
        a {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: center;
          justify-items: center;
          align-content: center;
          align-items: center;
          color: rgb(53, 5, 5);
        }
      }
    }

    .textfieldTypesPage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
      grid-gap: 15vw;
      top: 50px;
    }
  }
}
