.paywallWrapper {
    min-height: 100vh;
}
.paywallDetails {
    display: flex;
    flex-direction: column;
    color: #595959;
    padding: 16px;
    .premiumAccess {
        margin-top: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        img {
            height: 20px;
        }
        div {
            margin-left: 5px;
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
        }
    }
    .rocketContainer {
        display: flex;
        align-items: center;
        padding: 24px;
        color: #FFF;
        font-size: 13px;
        line-height: 16px;
        background: linear-gradient(81deg, #000D25 0%, #3D52A1 100%);
        border-radius: 16px;
        margin-top: 24px;
        div {
            text-align: left;
            margin-left: 10px;
        }
    }
    .premiumDetails {
        text-align: center;
        margin-top: 36px;
        margin-bottom: 24px;
        ul {
            text-align: left;
            font-size: 14px;
            line-height: 24px;
            li {
                margin-bottom: 16px;
            }
        }
    }
}