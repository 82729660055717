.zeroState {
  height: 100vh;
  background-color: #fff;
  position: relative;
}
.zeroSubtext {
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: #989898;
}

.zeroBox {
  height: 100px;
  top: 398px;
  border: 1px solid rgba(0, 0, 0, 0.36);
  box-sizing: border-box;
  border-radius: 8px;
  margin: 26px 16px;

  p {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #000000;
    margin-bottom: 0px;
  }
}

.submitButton__section {
  display: grid;
  grid-template-columns: 1fr;
  // width: 100%;
  justify-items: center;
  margin: 0px 0px 50px 0px;
  padding: 16px;
  .submitButton {
    width: 100%;
    text-transform: none;
    padding: 8px 0;
    padding: 16px 0;
  }
}