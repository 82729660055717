.exampleWrapper {
    min-height: 100vh;
}
.exampleDetails {
    display: flex;
    flex-direction: column;
    color: #595959;
    padding: 16px;
    text-align: left;
    div {
        margin-top: 16px;
    }
}