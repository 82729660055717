@media screen and (max-width: 1112px) {
  .auxColorPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .auxColorPage__title {
      position: relative;
      height: 170px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        color: maroon;
      }
    }

    .auxColorPage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      top: 25px;

      .auxColorPage__row1 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;

        .auxColorPage__ro1col1 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;

          .auxColorPage__box1 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            width: 300px;
            background: #43a047;
            color: white;
            border: 1px solid gray;
          }
        }

        .auxColorPage__row1col2 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
          top: 25px;

          .auxColorPage__box2 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            width: 300px;
            background: #ffa000;
            color: white;
            border: 1px solid gray;
          }
        }
      }

      .auxColorPage__row2 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;
        top: 50px;

        .auxColorPage__row2col1 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;

          .auxColorPage__box3 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            width: 300px;
            background: #43a047;
            color: white;
            border: 1px solid gray;
          }
        }

        .auxColorPage__row2col2 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
          top: 25px;

          .auxColorPage__box4 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            width: 300px;
            background: #ffa000;
            color: white;
            border: 1px solid gray;
          }
        }
      }
    }
  }
}

@media (min-width: 1112px) {
  .auxColorPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .auxColorPage__title {
      position: relative;
      height: 170px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        color: maroon;
      }
    }

    .auxColorPage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      top: 25px;

      .auxColorPage__row1 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20vw;
        justify-items: center;

        .auxColorPage__ro1col1 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;

          .auxColorPage__box1 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            width: 300px;
            background: #43a047;
            color: white;
            border: 1px solid gray;
          }
        }

        .auxColorPage__row1col2 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;

          .auxColorPage__box2 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            width: 300px;
            background: #ffa000;
            color: white;
            border: 1px solid gray;
          }
        }
      }

      .auxColorPage__row2 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20vw;
        justify-items: center;
        top: 50px;

        .auxColorPage__row2col1 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;

          .auxColorPage__box3 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            width: 300px;
            background: #b00020;
            color: white;
            border: 1px solid gray;
          }
        }

        .auxColorPage__row2col2 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;

          .auxColorPage__box4 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            width: 300px;
            background: #1976d2;
            color: white;
            border: 1px solid gray;
          }
        }
      }
    }
  }
}
