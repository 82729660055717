.listPageWrapper {
  // padding: 0px 0px 10px;
  padding: 0 20px 20px;
  display: flex;
  flex-direction: column;
}

.listDetails {
  // padding: 16px;
  // text-align: left;
  // margin: 0 15px;
  padding: 24px 18px;
  background-color: white;
  margin-top: 20px;
  border-radius: 24px;
  min-height: 24px;
  transition: height ease-in-out 0.25s;
  overflow: hidden;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 24px rgba(0, 0, 0, 0.064);

  display: flex;
  align-items: center;
  justify-content: space-between;

  h5 {
    padding: 0px;
    margin: 0;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    color: #595959;
  }

  h3 {
    padding: 5px 0px 0px;
    margin: 0;
    font-size: 17px;
    font-family: 'Open Sans';
    line-height: 25px;
    text-transform: uppercase;
    font-style: normal;
    color: #595959;
  }

  p {
    font-size: 11px;
    line-height: 16px;
    color: #989898;
  }
}

.listSubWrapper {
  padding: 0 20px 20px;
}

.listHurdleCount {
  background: #689AF5;
  border-radius: 15px;  
  color: white;
  width: 60%;
  padding: 7px;
  font-size: 11px;
  line-height: 11px;
  align-items: center;
  text-align: center;
  font-family: 'Open Sans';
  font-weight: bold;
}

.listOverflow {
  height: 80px;
  overflow: auto;
  width: 80%;
}

.listSection {
  display: flex;
  justify-content: space-between;
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
}

.btnContainer {
  display: flex;
  align-items: center;
  background: #E4E4E4;
  color: #fff;
  height: 24px;
  width: 24px;
  border-radius: 50%;
}