.mcPageWrapper {
  background-color: #e4e4e4;
  padding: 10px 0px;
}

.mcDetails {
  padding: 16px;
  text-align: left;
  background-color: white;
  display: flex;
  justify-content: space-between;
  margin: 10px 10px;
  border-radius: 8px;
  height: 120px;

  h5 {
    padding: 10px 0px;
    margin: 0;
    font-family: 'Open Sans';
    font-style: normal;
    // font-size: 26px;
    line-height: 31px;
    color: #595959;
  }

  h3 {
    padding: 10px 0px;
    margin: 0;
    font-style: normal;
    font-size: 22px;
    font-family: 'Open Sans';
    line-height: 25px;
    text-transform: uppercase;
    color: #276ef1;
  }

  p {
    font-size: 11px;
    line-height: 16px;
    color: #989898;
  }
}

.mcSubWrapper {
  padding: 0 20px 20px;
}
