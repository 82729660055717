// .reset-password-modal {
  .reset-pw-form-wrapper {
    > div {
      padding-bottom: 33px;
    }
    .reset-pw-title {
      color: #595959;
      font-size: 17px;
      line-height: 20px;
      text-align: left;
      padding: 0 16px 24px 16px;
      font-weight: 600;
    }
    .desc {
      color: #1F58C1;
      font-size: 14px;
      line-height: 17px;
      margin-top: 16px;
    }
    .text-red {
      color: #FF0000;
    }
  }
// }
