@media screen and (max-width: 600px) {
  .imageSpacingPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;

    .imageSpacingPage__title {
      position: relative;
      display: grid;
      align-content: center;
      height: 170px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
    }

    .imageSpacingPage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      justify-content: center;
      width: 100%;

      .imageSpacingPage__image1 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        height: 100%;
        padding-bottom: 40px;
        top: 50px;
        background: rgb(214, 214, 214);

        img {
          position: relative;
          z-index: 0;
          top: 0px;
          left: 0px;
          width: 100%;
        }
        .imageSpacingPage__heading1 {
          position: absolute;
          display: grid;
          grid-template-columns: 2fr auto;
          top: 150px;
          width: 100%;
          width: 100%;
          .imageSpacingPage__heading__blank {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
          }
          .imageSpacingPage__heading__content {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
          }
        }
        .imageSpacingPage__tile1 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          top: 10px;
          left: 25px;
          justify-items: start;
          width: 100%;
          height: 100%;

          .tile1__title {
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 17px;
            line-height: 23px;
          }
          .tile1__body {
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 17px;
            line-height: 23px;
          }
          .tile1__ctaButton {
            position: relative;
            top: 10px;
          }
        }
      }

      .imageSpacingPage__image2 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        height: 100%;
        padding-bottom: 40px;
        top: 100px;
        background: rgb(214, 214, 214);

        img {
          position: relative;
          z-index: 0;
          top: 0px;
          left: 0px;
          width: 100%;
        }

        .imageSpacingPage__tile2 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          top: 10px;
          left: 25px;
          justify-items: start;
          width: 100%;
          height: 100%;

          .tile2__title {
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 17px;
            line-height: 23px;
          }
          .tile2__body {
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 17px;
            line-height: 23px;
          }
          .tile2__ctaButton {
            position: relative;
            top: 10px;
          }
        }
      }
    }
  }
}
