@media (min-width: 320px) {
  //=========================== min-width 320px
  .cardsPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .cardsPage__title {
      position: relative;
      display: grid;
      align-items: center;
      height: 170px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        text-decoration: none;
        color: maroon !important;
        a {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: center;
          justify-items: center;
          align-content: center;
          align-items: center;
          color: rgb(53, 5, 5);
        }
      }
    }
    .cardsPage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      top: 50px;
      width: 100%;
      align-items: center;
      justify-items: center;

      //==================================== card 1
      .cardsPage__card1 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        height: 100%;

        .gradientBg {
          position: absolute;
          z-index: 500;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            158.46deg,
            rgba(0, 189, 231, 0.7) 0%,
            rgba(225, 0, 149, 0.7) 96.88%
          );
        }

        img {
          position: relative;
          z-index: 0;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
        }

        .card1__header {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 4fr;
          align-items: center;
          z-index: 1000;
          color: white;
        }

        .card1__title {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          justify-content: start;
          z-index: 1000;
          top: 50px;
          color: white;
        }

        .card1__body {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          text-align: left;
          justify-content: start;
          z-index: 1000;
          top: 100px;
          color: white;
          .card1__content__ctaButton {
            position: relative;
            .ctaButton1 {
              color: white;
              border: 1px solid white;
            }
          }
        }

        .card1__actions {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 2fr;
          align-items: center;
          text-align: left;
          justify-content: start;
          z-index: 1000;
          bottom: 10px;
          color: white;
          .cardType {
            color: #00ffc2;
            border: 1px solid #00ffc2;
          }
          .unclaimedButton {
            color: #fff500;
            border: 1px solid #fff500;
          }
        }
      }
      //==================================== card 1

      //==================================== card 2
      .cardsPage__card2 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        top: 50px;
        width: 100%;
        height: 100%;

        .gradientBg {
          position: absolute;
          z-index: 500;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            158.46deg,
            rgba(0, 189, 231, 0.7) 0%,
            rgba(225, 0, 149, 0.7) 96.88%
          );
        }

        img {
          position: relative;
          z-index: 0;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
        }

        .card2__header {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 4fr;
          align-items: center;
          z-index: 1000;
          color: white;
        }

        .card2__title {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          justify-content: start;
          z-index: 1000;
          top: 50px;
          color: white;
        }

        .card2__body {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          text-align: left;
          justify-content: start;
          z-index: 1000;
          top: 100px;
          color: white;
          .ctaBUtton2 {
            color: white;
            border: 1px solid white;
          }
        }

        .card2__actions {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 2fr;
          align-items: center;
          text-align: left;
          justify-content: start;
          z-index: 1000;
          bottom: 10px;
          color: white;
          .cardType {
            color: #00ffc2;
            border: 1px solid #00ffc2;
          }
          .unclaimedButton {
            color: #fff500;
            border: 1px solid #fff500;
          }
        }
      }
      //==================================== card 2
      //==================================== card 3
      .cardsPage__card3 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        top: 100px;
        width: 100%;
        height: 100%;
        background: #f9f9f9;

        img {
          position: relative;
          z-index: 0;
          top: 0px;
          left: 0px;
          width: 100%;
        }

        .card3__actions {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 2fr;
          align-items: center;
          text-align: left;
          justify-content: start;
          z-index: 1000;
          top: 160px;
          color: white;
          .cardType {
            color: #00ffc2;
            border: 1px solid #00ffc2;
          }
          .unclaimedButton {
            color: #c4c4c4;
            border: 1px solid #c4c4c4;
          }
        }

        .card3__title {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          justify-content: start;
          z-index: 1000;
          top: 250px;
          color: #595959;
        }
        .card3__body {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          text-align: left;
          justify-content: start;
          z-index: 1000;
          top: 300px;
          color: #8b8b8b;
          .card3__content__ctaButton {
            position: relative;
            display: grid;
            grid-template-columns: 1fr 1fr;
            .ctaButton3 {
              position: relative;
              display: grid;
              grid-template-columns: 1fr;
              top: 150px;
              color: white;
              border: 1px solid white;
            }
          }
        }
        .card3__footer {
          position: relative;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 5vw;
          width: 100%;
          height: 100%;
          top: 300px;

          .card3__content__ctaButton {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
          }
          .card3__header {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            z-index: 1000;
            color: #595959;
          }
        }
      }
      //==================================== card 3

      //==================================== card 4
      .cardsPage__card4 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        top: 500px;
        width: 100%;
        height: 100%;
        background: #fbfbfb;

        img {
          position: relative;
          z-index: 0;
          top: 0px;
          left: 0px;
          width: 100%;
        }

        .card4__header {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 4fr;
          top: 0px;
          align-items: center;
          z-index: 1000;
          color: white;
        }

        .card4__actions {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 2fr;
          align-items: center;
          text-align: left;
          justify-content: start;
          z-index: 1000;
          top: 200px;
          color: white;
          .cardType {
            color: #00ffc2;
            border: 1px solid #00ffc2;
          }
          .unclaimedButton {
            color: #c4c4c4;
            border: 1px solid #c4c4c4;
          }
        }

        .card4__title {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-self: start;
          justify-content: start;
          z-index: 1000;
          top: 10px;
          color: #595959;
        }

        .card4__body {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          text-align: left;
          justify-content: start;
          z-index: 1000;
          top: 0px;
          color: #8b8b8b;
          .card4__content__ctaButton {
            position: relative;
            display: grid;
            grid-template-columns: 1fr 1fr;
            top: 0px;
            padding-bottom: 20px;
            .ctaButton4 {
              position: relative;
              display: grid;
              grid-template-columns: 1fr;
              top: 0px;
              color: #595959;
              border: 1px solid #595959;
            }
          }
        }
      }
      //==================================== card 4
    }
  }
} //=========================== min-width 320px

@media (min-width: 430px) {
  //=========================== min-width 430px
  .cardsPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .cardsPage__title {
      position: relative;
      display: grid;
      align-items: center;
      height: 170px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        text-decoration: none;
        color: maroon !important;
        a {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: center;
          justify-items: center;
          align-content: center;
          align-items: center;
          color: rgb(53, 5, 5);
        }
      }
    }
    .cardsPage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      top: 50px;
      width: 100%;
      align-items: center;
      justify-items: center;

      //==================================== card 1
      .cardsPage__card1 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        height: 100%;

        .gradientBg {
          position: absolute;
          z-index: 500;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            158.46deg,
            rgba(0, 189, 231, 0.7) 0%,
            rgba(225, 0, 149, 0.7) 96.88%
          );
        }

        img {
          position: relative;
          z-index: 0;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
        }

        .card1__header {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 4fr;
          align-items: center;
          z-index: 1000;
          color: white;
        }

        .card1__title {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          justify-content: start;
          z-index: 1000;
          top: 120px;
          padding: 0px 20px;
          font-size: 17px;
          color: white;
        }

        .card1__body {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          text-align: left;
          justify-content: start;
          z-index: 1000;
          top: 150px;
          padding: 0px 20px;
          font-size: 17px;
          color: white;
          .card1__content__ctaButton {
            position: relative;
            top: 50px;
            .ctaButton1 {
              color: white;
              border: 1px solid white;
            }
          }
        }

        .card1__actions {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 2fr;
          grid-template-columns: 1fr 2fr auto;
          grid-gap: 2vw;
          justify-content: center;
          width: 100%;
          z-index: 1000;
          bottom: 10px;
          color: white;
          .cardType {
            color: #00ffc2;
            border: 1px solid #00ffc2;
          }
          .unclaimedButton {
            color: #fff500;
            border: 1px solid #fff500;
          }
        }
      }
      //==================================== card 1

      //==================================== card 2
      .cardsPage__card2 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        top: 50px;
        width: 100%;
        height: 100%;

        .gradientBg {
          position: absolute;
          z-index: 500;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            158.46deg,
            rgba(0, 189, 231, 0.7) 0%,
            rgba(225, 0, 149, 0.7) 96.88%
          );
        }

        img {
          position: relative;
          z-index: 0;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
        }

        .card2__header {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 4fr;
          align-items: center;
          z-index: 1000;
          color: white;
        }

        .card2__title {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          justify-content: start;
          top: 80px;
          padding: 0px 20px;
          z-index: 1000;
          padding: 0px 20px;
          font-size: 17px;
          color: white;
        }

        .card2__body {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          text-align: left;
          justify-content: start;
          z-index: 1000;
          padding: 0px 20px;
          top: 150px;
          padding: 0px 20px;
          font-size: 17px;
          color: white;
          .card2__content__ctaButton {
            position: relative;
            top: 50px;
            .ctaBUtton2 {
              color: white;
              border: 1px solid white;
            }
          }
        }

        .card2__actions {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 2fr auto;
          grid-gap: 2vw;
          justify-content: center;
          width: 100%;
          z-index: 1000;
          bottom: 10px;
          color: white;
          .cardType {
            color: #00ffc2;
            border: 1px solid #00ffc2;
          }
          .unclaimedButton {
            color: #fff500;
            border: 1px solid #fff500;
          }
        }
      }
      //==================================== card 2
      //==================================== card 3
      .cardsPage__card3 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        top: 100px;
        width: 100%;
        height: 100%;
        background: #f9f9f9;

        img {
          position: relative;
          z-index: 0;
          top: 0px;
          left: 0px;
          width: 100%;
        }

        .card3__actions {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 2fr auto;
          grid-gap: 2vw;
          justify-content: center;
          width: 100%;
          z-index: 1000;
          top: 200px;
          color: white;
          .cardType {
            color: #00ffc2;
            border: 1px solid #00ffc2;
          }
          .unclaimedButton {
            color: #c4c4c4;
            border: 1px solid #c4c4c4;
          }
        }

        .card3__title {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          justify-content: start;
          z-index: 1000;
          padding: 0px 20px;
          top: 250px;
          font-size: 17px;
          color: #595959;
        }
        .card3__body {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          text-align: left;
          justify-content: start;
          z-index: 1000;
          top: 300px;
          padding: 0px 20px;
          font-size: 17px;
          color: #8b8b8b;
          .card3__content__ctaButton {
            position: relative;
            display: grid;
            grid-template-columns: 1fr 1fr;
            .ctaButton3 {
              position: relative;
              display: grid;
              grid-template-columns: 1fr;
              top: 150px;
              color: white;
              border: 1px solid white;
            }
          }
        }
        .card3__footer {
          position: relative;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 5vw;
          width: 100%;
          height: 100%;
          top: 250px;

          .card3__content__ctaButton {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
          }
          .card3__header {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            z-index: 1000;
            color: #595959;
          }
        }
      }
      //==================================== card 3

      //==================================== card 4
      .cardsPage__card4 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        top: 450px;
        width: 100%;
        height: 100%;
        background: #fbfbfb;

        img {
          position: relative;
          z-index: 0;
          top: 0px;
          left: 0px;
          width: 100%;
        }

        .card4__header {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 4fr;
          top: 0px;
          align-items: center;
          z-index: 1000;
          color: white;
        }

        .card4__actions {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 2fr auto;
          grid-gap: 2vw;
          justify-content: center;
          width: 100%;
          z-index: 1000;
          top: 330px;
          color: white;
          .cardType {
            color: #00ffc2;
            border: 1px solid #00ffc2;
          }
          .unclaimedButton {
            color: #c4c4c4;
            border: 1px solid #c4c4c4;
          }
        }

        .card4__title {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-self: start;
          justify-content: start;
          z-index: 1000;
          top: 10px;
          color: #595959;
        }

        .card4__body {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          text-align: left;
          justify-content: start;
          z-index: 1000;
          top: 0px;
          color: #8b8b8b;
          .card4__content__ctaButton {
            position: relative;
            display: grid;
            grid-template-columns: 1fr 1fr;
            top: 0px;
            padding-bottom: 20px;
            .ctaButton4 {
              position: relative;
              display: grid;
              grid-template-columns: 1fr;
              top: 0px;
              color: #595959;
              border: 1px solid #595959;
            }
          }
        }
      }
      //==================================== card 4
    }
  }
} //=========================== min-width 430px

@media (min-width: 550px) {
  //=========================== min-width 550px
  .cardsPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .cardsPage__title {
      position: relative;
      display: grid;
      align-items: center;
      height: 170px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        text-decoration: none;
        color: maroon !important;
        a {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: center;
          justify-items: center;
          align-content: center;
          align-items: center;
          color: rgb(53, 5, 5);
        }
      }
    }
    .cardsPage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      top: 50px;
      width: 100%;
      align-items: center;
      justify-items: center;

      //==================================== card 1
      .cardsPage__card1 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        height: 100%;

        .gradientBg {
          position: absolute;
          z-index: 500;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            158.46deg,
            rgba(0, 189, 231, 0.7) 0%,
            rgba(225, 0, 149, 0.7) 96.88%
          );
        }

        img {
          position: relative;
          z-index: 0;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
        }

        .card1__header {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 4fr;
          align-items: center;
          z-index: 1000;
          color: white;
        }

        .card1__title {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          justify-content: start;
          z-index: 1000;
          top: 120px;
          padding: 0px 20px;
          font-size: 17px;
          color: white;
        }

        .card1__body {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          text-align: left;
          justify-content: start;
          z-index: 1000;
          top: 150px;
          padding: 0px 20px;
          font-size: 17px;
          color: white;
          .card1__content__ctaButton {
            position: relative;
            top: 50px;
            .ctaButton1 {
              color: white;
              border: 1px solid white;
            }
          }
        }

        .card1__actions {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 2fr;
          grid-template-columns: 1fr 2fr auto;
          grid-gap: 2vw;
          justify-content: center;
          width: 100%;
          z-index: 1000;
          bottom: 10px;
          color: white;
          .cardType {
            color: #00ffc2;
            border: 1px solid #00ffc2;
          }
          .unclaimedButton {
            color: #fff500;
            border: 1px solid #fff500;
          }
        }
      }
      //==================================== card 1

      //==================================== card 2
      .cardsPage__card2 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        top: 50px;
        width: 100%;
        height: 100%;

        .gradientBg {
          position: absolute;
          z-index: 500;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            158.46deg,
            rgba(0, 189, 231, 0.7) 0%,
            rgba(225, 0, 149, 0.7) 96.88%
          );
        }

        img {
          position: relative;
          z-index: 0;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
        }

        .card2__header {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 4fr;
          align-items: center;
          z-index: 1000;
          color: white;
        }

        .card2__title {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          justify-content: start;
          top: 80px;
          padding: 0px 20px;
          z-index: 1000;
          padding: 0px 20px;
          font-size: 17px;
          color: white;
        }

        .card2__body {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          text-align: left;
          justify-content: start;
          z-index: 1000;
          padding: 0px 20px;
          top: 150px;
          padding: 0px 20px;
          font-size: 17px;
          color: white;
          .card2__content__ctaButton {
            position: relative;
            top: 50px;
            .ctaBUtton2 {
              color: white;
              border: 1px solid white;
            }
          }
        }

        .card2__actions {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 2fr auto;
          grid-gap: 2vw;
          justify-content: center;
          width: 100%;
          z-index: 1000;
          bottom: 10px;
          color: white;
          .cardType {
            color: #00ffc2;
            border: 1px solid #00ffc2;
          }
          .unclaimedButton {
            color: #fff500;
            border: 1px solid #fff500;
          }
        }
      }
      //==================================== card 2
      //==================================== card 3
      .cardsPage__card3 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        top: 100px;
        width: 100%;
        height: 100%;
        background: #f9f9f9;

        img {
          position: relative;
          z-index: 0;
          top: 0px;
          left: 0px;
          width: 100%;
        }

        .card3__actions {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 2fr auto;
          grid-gap: 2vw;
          justify-content: center;
          width: 100%;
          z-index: 1000;
          top: 250px;
          color: white;
          .cardType {
            color: #00ffc2;
            border: 1px solid #00ffc2;
          }
          .unclaimedButton {
            color: #c4c4c4;
            border: 1px solid #c4c4c4;
          }
        }

        .card3__title {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          justify-content: start;
          z-index: 1000;
          padding: 0px 20px;
          top: 300px;
          font-size: 17px;
          color: #595959;
        }
        .card3__body {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          text-align: left;
          justify-content: start;
          z-index: 1000;
          top: 350px;
          padding: 0px 20px;
          font-size: 17px;
          color: #8b8b8b;
          .card3__content__ctaButton {
            position: relative;
            display: grid;
            grid-template-columns: 1fr 1fr;
            .ctaButton3 {
              position: relative;
              display: grid;
              grid-template-columns: 1fr;
              top: 150px;
              color: white;
              border: 1px solid white;
            }
          }
        }
        .card3__footer {
          position: relative;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 5vw;
          width: 100%;
          height: 100%;
          top: 250px;

          .card3__content__ctaButton {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
          }
          .card3__header {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            z-index: 1000;
            color: #595959;
          }
        }
      }
      //==================================== card 3

      //==================================== card 4
      .cardsPage__card4 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        top: 450px;
        width: 100%;
        height: 100%;
        background: #fbfbfb;

        img {
          position: relative;
          z-index: 0;
          top: 0px;
          left: 0px;
          width: 100%;
        }

        .card4__header {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 4fr;
          top: 0px;
          align-items: center;
          z-index: 1000;
          color: white;
        }

        .card4__actions {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 2fr auto;
          grid-gap: 2vw;
          justify-content: center;
          width: 100%;
          z-index: 1000;
          top: 450px;
          color: white;
          .cardType {
            color: #00ffc2;
            border: 1px solid #00ffc2;
          }
          .unclaimedButton {
            color: #c4c4c4;
            border: 1px solid #c4c4c4;
          }
        }

        .card4__title {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-self: start;
          justify-content: start;
          z-index: 1000;
          top: 10px;
          color: #595959;
        }

        .card4__body {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          text-align: left;
          justify-content: start;
          z-index: 1000;
          top: 0px;
          color: #8b8b8b;
          .card4__content__ctaButton {
            position: relative;
            display: grid;
            grid-template-columns: 1fr 1fr;
            top: 0px;
            margin-bottom: 50px;
            .ctaButton4 {
              position: relative;
              display: grid;
              grid-template-columns: 1fr;
              top: 60px;
              color: #595959;
              border: 1px solid #595959;
            }
          }
        }
      }
      //==================================== card 4
    }
  }
} //=========================== min-width 550px

@media (min-width: 600px) {
  //=========================== min-width 600px
  .cardsPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .cardsPage__title {
      position: relative;
      display: grid;
      align-items: center;
      height: 170px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        text-decoration: none;
        color: maroon !important;
        a {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: center;
          justify-items: center;
          align-content: center;
          align-items: center;
          color: rgb(53, 5, 5);
        }
      }
    }
    .cardsPage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      top: 50px;
      width: 100%;
      align-items: center;
      justify-items: center;

      //==================================== card 1
      .cardsPage__card1 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        height: 100%;

        .gradientBg {
          position: absolute;
          z-index: 500;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            158.46deg,
            rgba(0, 189, 231, 0.7) 0%,
            rgba(225, 0, 149, 0.7) 96.88%
          );
        }

        img {
          position: relative;
          z-index: 0;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
        }

        .card1__header {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 4fr;
          align-items: center;
          z-index: 1000;
          color: white;
        }

        .card1__title {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          justify-content: start;
          z-index: 1000;
          top: 80px;
          padding: 0px 20px;
          font-size: 17px;
          color: white;
        }

        .card1__body {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          text-align: left;
          justify-content: start;
          z-index: 1000;
          top: 110px;
          padding: 0px 20px;
          font-size: 17px;
          color: white;
          .card1__content__ctaButton {
            position: relative;
            top: 10px;
            .ctaButton1 {
              color: white;
              border: 1px solid white;
            }
          }
        }

        .card1__actions {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 2fr;
          grid-template-columns: 1fr 2fr auto;
          grid-gap: 2vw;
          justify-content: center;
          width: 100%;
          z-index: 1000;
          bottom: 10px;
          color: white;
          .cardType {
            color: #00ffc2;
            border: 1px solid #00ffc2;
          }
          .unclaimedButton {
            color: #fff500;
            border: 1px solid #fff500;
          }
        }
      }
      //==================================== card 1

      //==================================== card 2
      .cardsPage__card2 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        top: 50px;
        width: 100%;
        height: 100%;

        .gradientBg {
          position: absolute;
          z-index: 500;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            158.46deg,
            rgba(0, 189, 231, 0.7) 0%,
            rgba(225, 0, 149, 0.7) 96.88%
          );
        }

        img {
          position: relative;
          z-index: 0;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
        }

        .card2__header {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 4fr;
          align-items: center;
          z-index: 1000;
          color: white;
        }

        .card2__title {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          justify-content: start;
          top: 50px;
          padding: 0px 20px;
          z-index: 1000;
          padding: 0px 20px;
          font-size: 17px;
          color: white;
        }

        .card2__body {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          text-align: left;
          justify-content: start;
          z-index: 1000;
          padding: 0px 20px;
          top: 130px;
          padding: 0px 20px;
          font-size: 17px;
          color: white;
          .card2__content__ctaButton {
            position: relative;
            top: 0px;
            .ctaBUtton2 {
              color: white;
              border: 1px solid white;
            }
          }
        }

        .card2__actions {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 2fr auto;
          grid-gap: 2vw;
          justify-content: center;
          width: 100%;
          z-index: 1000;
          bottom: 10px;
          color: white;
          .cardType {
            color: #00ffc2;
            border: 1px solid #00ffc2;
          }
          .unclaimedButton {
            color: #fff500;
            border: 1px solid #fff500;
          }
        }
      }
      //==================================== card 2
      //==================================== card 3
      .cardsPage__card3 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        top: 100px;
        width: 100%;
        height: 100%;
        background: #f9f9f9;

        img {
          position: relative;
          z-index: 0;
          top: 0px;
          left: 0px;
          width: 100%;
        }

        .card3__actions {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 2fr auto;
          grid-gap: 2vw;
          justify-content: center;
          width: 100%;
          z-index: 1000;
          top: 170px;
          color: white;
          .cardType {
            color: #00ffc2;
            border: 1px solid #00ffc2;
          }
          .unclaimedButton {
            color: #c4c4c4;
            border: 1px solid #c4c4c4;
          }
        }

        .card3__title {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          justify-content: start;
          z-index: 1000;
          padding: 0px 20px;
          top: 220px;
          font-size: 17px;
          color: #595959;
        }
        .card3__body {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          text-align: left;
          justify-content: start;
          z-index: 1000;
          top: 250px;
          padding: 0px 20px;
          font-size: 17px;
          color: #8b8b8b;
          .card3__content__ctaButton {
            position: relative;
            display: grid;
            grid-template-columns: 1fr 1fr;
            .ctaButton3 {
              position: relative;
              display: grid;
              grid-template-columns: 1fr;
              top: 150px;
              color: white;
              border: 1px solid white;
            }
          }
        }
        .card3__footer {
          position: relative;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 5vw;
          width: 100%;
          height: 100%;
          top: 250px;

          .card3__content__ctaButton {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
          }
          .card3__header {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            z-index: 1000;
            color: #595959;
          }
        }
      }
      //==================================== card 3

      //==================================== card 4
      .cardsPage__card4 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        top: 450px;
        width: 100%;
        height: 100%;
        background: #fbfbfb;

        img {
          position: relative;
          z-index: 0;
          top: 0px;
          left: 0px;
          width: 100%;
        }

        .card4__header {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 4fr;
          top: 0px;
          align-items: center;
          z-index: 1000;
          color: white;
        }

        .card4__actions {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 2fr auto;
          grid-gap: 2vw;
          justify-content: center;
          width: 100%;
          z-index: 1000;
          top: 250px;
          color: white;
          .cardType {
            color: #00ffc2;
            border: 1px solid #00ffc2;
          }
          .unclaimedButton {
            color: #c4c4c4;
            border: 1px solid #c4c4c4;
          }
        }

        .card4__title {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-self: start;
          justify-content: start;
          z-index: 1000;
          top: 10px;
          color: #595959;
        }

        .card4__body {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          text-align: left;
          justify-content: start;
          z-index: 1000;
          top: 0px;
          color: #8b8b8b;
          .card4__content__ctaButton {
            position: relative;
            display: grid;
            grid-template-columns: 1fr 1fr;
            top: 0px;
            margin-bottom: 50px;
            .ctaButton4 {
              position: relative;
              display: grid;
              grid-template-columns: 1fr;
              top: 40px;
              color: #595959;
              border: 1px solid #595959;
            }
          }
        }
      }
      //==================================== card 4
    }
  }
} //=========================== min-width 600px

@media (min-width: 650px) {
  //=========================== min-width 650px
  .cardsPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .cardsPage__title {
      position: relative;
      display: grid;
      align-items: center;
      height: 170px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        text-decoration: none;
        color: maroon !important;
        a {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: center;
          justify-items: center;
          align-content: center;
          align-items: center;
          color: rgb(53, 5, 5);
        }
      }
    }
    .cardsPage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      top: 50px;
      width: 100%;
      align-items: center;
      justify-items: center;

      //==================================== card 1
      .cardsPage__card1 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        height: 100%;

        .gradientBg {
          position: absolute;
          z-index: 500;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            158.46deg,
            rgba(0, 189, 231, 0.7) 0%,
            rgba(225, 0, 149, 0.7) 96.88%
          );
        }

        img {
          position: relative;
          z-index: 0;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
        }

        .card1__header {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 4fr;
          align-items: center;
          z-index: 1000;
          color: white;
        }

        .card1__title {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          justify-content: start;
          z-index: 1000;
          top: 80px;
          padding: 0px 20px;
          font-size: 17px;
          color: white;
        }

        .card1__body {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          text-align: left;
          justify-content: start;
          z-index: 1000;
          top: 110px;
          padding: 0px 20px;
          font-size: 17px;
          color: white;
          .card1__content__ctaButton {
            position: relative;
            top: 10px;
            .ctaButton1 {
              color: white;
              border: 1px solid white;
            }
          }
        }

        .card1__actions {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 2fr;
          grid-template-columns: 1fr 2fr auto;
          grid-gap: 2vw;
          justify-content: center;
          width: 100%;
          z-index: 1000;
          bottom: 10px;
          color: white;
          .cardType {
            color: #00ffc2;
            border: 1px solid #00ffc2;
          }
          .unclaimedButton {
            color: #fff500;
            border: 1px solid #fff500;
          }
        }
      }
      //==================================== card 1

      //==================================== card 2
      .cardsPage__card2 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        top: 50px;
        width: 100%;
        height: 100%;

        .gradientBg {
          position: absolute;
          z-index: 500;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            158.46deg,
            rgba(0, 189, 231, 0.7) 0%,
            rgba(225, 0, 149, 0.7) 96.88%
          );
        }

        img {
          position: relative;
          z-index: 0;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
        }

        .card2__header {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 4fr;
          align-items: center;
          z-index: 1000;
          color: white;
        }

        .card2__title {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          justify-content: start;
          top: 50px;
          padding: 0px 20px;
          z-index: 1000;
          padding: 0px 20px;
          font-size: 17px;
          color: white;
        }

        .card2__body {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          text-align: left;
          justify-content: start;
          z-index: 1000;
          padding: 0px 20px;
          top: 130px;
          padding: 0px 20px;
          font-size: 17px;
          color: white;
          .card2__content__ctaButton {
            position: relative;
            top: 0px;
            .ctaBUtton2 {
              color: white;
              border: 1px solid white;
            }
          }
        }

        .card2__actions {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 2fr auto;
          grid-gap: 2vw;
          justify-content: center;
          width: 100%;
          z-index: 1000;
          bottom: 10px;
          color: white;
          .cardType {
            color: #00ffc2;
            border: 1px solid #00ffc2;
          }
          .unclaimedButton {
            color: #fff500;
            border: 1px solid #fff500;
          }
        }
      }
      //==================================== card 2
      //==================================== card 3
      .cardsPage__card3 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        top: 100px;
        width: 100%;
        height: 100%;
        background: #f9f9f9;

        img {
          position: relative;
          z-index: 0;
          top: 0px;
          left: 0px;
          width: 100%;
        }

        .card3__actions {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 2fr auto;
          grid-gap: 2vw;
          justify-content: center;
          width: 100%;
          z-index: 1000;
          top: 200px;
          color: white;
          .cardType {
            color: #00ffc2;
            border: 1px solid #00ffc2;
          }
          .unclaimedButton {
            color: #c4c4c4;
            border: 1px solid #c4c4c4;
          }
        }

        .card3__title {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          justify-content: start;
          z-index: 1000;
          padding: 0px 20px;
          top: 220px;
          font-size: 17px;
          color: #595959;
        }
        .card3__body {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          text-align: left;
          justify-content: start;
          z-index: 1000;
          top: 250px;
          padding: 0px 20px;
          font-size: 17px;
          color: #8b8b8b;
          .card3__content__ctaButton {
            position: relative;
            display: grid;
            grid-template-columns: 1fr 1fr;
            .ctaButton3 {
              position: relative;
              display: grid;
              grid-template-columns: 1fr;
              top: 150px;
              color: white;
              border: 1px solid white;
            }
          }
        }
        .card3__footer {
          position: relative;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 5vw;
          width: 100%;
          height: 100%;
          top: 250px;

          .card3__content__ctaButton {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
          }
          .card3__header {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            z-index: 1000;
            color: #595959;
          }
        }
      }
      //==================================== card 3

      //==================================== card 4
      .cardsPage__card4 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        top: 450px;
        width: 100%;
        height: 100%;
        background: #fbfbfb;

        img {
          position: relative;
          z-index: 0;
          top: 0px;
          left: 0px;
          width: 100%;
        }

        .card4__header {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 4fr;
          top: 0px;
          align-items: center;
          z-index: 1000;
          color: white;
        }

        .card4__actions {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 2fr auto;
          grid-gap: 2vw;
          justify-content: center;
          width: 100%;
          z-index: 1000;
          top: 300px;
          color: white;
          .cardType {
            color: #00ffc2;
            border: 1px solid #00ffc2;
          }
          .unclaimedButton {
            color: #c4c4c4;
            border: 1px solid #c4c4c4;
          }
        }

        .card4__title {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-self: start;
          justify-content: start;
          z-index: 1000;
          top: 10px;
          color: #595959;
        }

        .card4__body {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          text-align: left;
          justify-content: start;
          z-index: 1000;
          padding: 0px 20px;
          top: 0px;
          color: #8b8b8b;
          .card4__content__ctaButton {
            position: relative;
            display: grid;
            grid-template-columns: 1fr 1fr;
            top: 0px;
            margin-bottom: 50px;
            .ctaButton4 {
              position: relative;
              display: grid;
              grid-template-columns: 1fr;
              top: 40px;
              color: #595959;
              border: 1px solid #595959;
            }
          }
        }
      }
      //==================================== card 4
    }
  }
} //=========================== min-width 650px

@media (min-width: 720px) {
  //=========================== min-width 720px
  .cardsPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .cardsPage__title {
      position: relative;
      display: grid;
      align-items: center;
      height: 170px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        text-decoration: none;
        color: maroon !important;
        a {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: center;
          justify-items: center;
          align-content: center;
          align-items: center;
          color: rgb(53, 5, 5);
        }
      }
      .cardsPage__container {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        top: 50px;
        width: 100%;
        align-items: center;
        justify-items: center;

        //==================================== card 1
        .cardsPage__card1 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          width: 100%;
          height: 100%;

          .gradientBg {
            position: absolute;
            z-index: 500;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              158.46deg,
              rgba(0, 189, 231, 0.7) 0%,
              rgba(225, 0, 149, 0.7) 96.88%
            );
          }

          img {
            position: relative;
            z-index: 0;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
          }

          .card1__header {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr 4fr;
            align-items: center;
            z-index: 1000;
            color: white;
          }

          .card1__title {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            justify-content: start;
            z-index: 1000;
            top: 22%;
            padding: 0px 20px;
            font-size: 17px;
            color: white;
          }

          .card1__body {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            text-align: left;
            justify-content: start;
            z-index: 1000;
            top: 40%;
            padding: 0px 20px;
            font-size: 17px;
            color: white;
            .card1__content__ctaButton {
              position: relative;
              top: 25%;
              .ctaButton1 {
                color: white;
                border: 1px solid white;
              }
            }
          }

          .card1__actions {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr 2fr;
            grid-template-columns: 1fr 2fr auto;
            grid-gap: 2vw;
            justify-content: center;
            width: 100%;
            z-index: 1000;
            bottom: 10px;
            color: white;
            .cardType {
              color: #00ffc2;
              border: 1px solid #00ffc2;
            }
            .unclaimedButton {
              color: #fff500;
              border: 1px solid #fff500;
            }
          }
        }
        //==================================== card 1

        //==================================== card 2
        .cardsPage__card2 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          top: 50px;
          width: 100%;
          height: 100%;

          .gradientBg {
            position: absolute;
            z-index: 500;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              158.46deg,
              rgba(0, 189, 231, 0.7) 0%,
              rgba(225, 0, 149, 0.7) 96.88%
            );
          }

          img {
            position: relative;
            z-index: 0;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
          }

          .card2__header {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr 4fr;
            align-items: center;
            z-index: 1000;
            color: white;
          }

          .card2__title {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            justify-content: start;
            top: 22%;
            padding: 0px 20px;
            z-index: 1000;
            padding: 0px 20px;
            font-size: 17px;
            color: white;
          }

          .card2__body {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            text-align: left;
            justify-content: start;
            z-index: 1000;
            padding: 0px 20px;
            top: 40%;
            padding: 0px 20px;
            font-size: 17px;
            color: white;
            .card2__content__ctaButton {
              position: relative;
              top: 25%;
              .ctaBUtton2 {
                color: white;
                border: 1px solid white;
              }
            }
          }

          .card2__actions {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr 2fr auto;
            grid-gap: 2vw;
            justify-content: center;
            width: 100%;
            z-index: 1000;
            bottom: 10px;
            color: white;
            .cardType {
              color: #00ffc2;
              border: 1px solid #00ffc2;
            }
            .unclaimedButton {
              color: #fff500;
              border: 1px solid #fff500;
            }
          }
        }
        //==================================== card 2
        //==================================== card 3
        .cardsPage__card3 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          top: 100px;
          width: 100%;
          height: 100%;
          background: #f9f9f9;

          img {
            position: relative;
            z-index: 0;
            top: 0px;
            left: 0px;
            width: 100%;
          }

          .card3__actions {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr 2fr auto;
            grid-gap: 2vw;
            justify-content: center;
            width: 100%;
            z-index: 1000;
            top: 85%;
            color: white;
            .cardType {
              color: #00ffc2;
              border: 1px solid #00ffc2;
            }
            .unclaimedButton {
              color: #c4c4c4;
              border: 1px solid #c4c4c4;
            }
          }

          .card3__title {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            justify-content: start;
            z-index: 1000;
            padding: 0px 20px;
            top: 100%;
            font-size: 17px;
            color: #595959;
          }
          .card3__body {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            text-align: left;
            justify-content: start;
            z-index: 1000;
            top: 120%;
            padding: 0px 20px;
            font-size: 17px;
            color: #8b8b8b;
            .card3__content__ctaButton {
              position: relative;
              display: grid;
              grid-template-columns: 1fr 1fr;
              .ctaButton3 {
                position: relative;
                display: grid;
                grid-template-columns: 1fr;
                top: 150px;
                color: white;
                border: 1px solid white;
              }
            }
          }
          .card3__footer {
            position: relative;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 5vw;
            width: 100%;
            height: 100%;
            top: 250px;

            .card3__content__ctaButton {
              position: relative;
              display: grid;
              grid-template-columns: 1fr;
            }
            .card3__header {
              position: relative;
              display: flex;
              flex-direction: row;
              align-items: center;
              z-index: 1000;
              color: #595959;
            }
          }
        }
        //==================================== card 3

        //==================================== card 4
        .cardsPage__card4 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          top: 450px;
          width: 100%;
          height: 100%;
          background: #fbfbfb;

          img {
            position: relative;
            z-index: 0;
            top: 0px;
            left: 0px;
            width: 100%;
          }

          .card4__header {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr 4fr;
            top: 0px;
            align-items: center;
            z-index: 1000;
            color: white;
          }

          .card4__actions {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr 2fr auto;
            grid-gap: 2vw;
            justify-content: center;
            width: 100%;
            z-index: 1000;
            top: 60%;
            color: white;
            .cardType {
              color: #00ffc2;
              border: 1px solid #00ffc2;
            }
            .unclaimedButton {
              color: #c4c4c4;
              border: 1px solid #c4c4c4;
            }
          }

          .card4__title {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            justify-self: start;
            justify-content: start;
            z-index: 1000;
            top: 10px;
            color: #595959;
          }

          .card4__body {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            text-align: left;
            justify-content: start;
            z-index: 1000;
            padding: 0px 20px;
            top: 0px;
            color: #8b8b8b;
            .card4__content__ctaButton {
              position: relative;
              display: grid;
              grid-template-columns: 1fr 1fr;
              top: 0px;
              margin-bottom: 50px;
              .ctaButton4 {
                position: relative;
                display: grid;
                grid-template-columns: 1fr;
                top: 40px;
                color: #595959;
                border: 1px solid #595959;
              }
            }
          }
        }
        //==================================== card 4
      }
    }
  } //=========================== min-width 720px

  @media (min-width: 850px) {
    //=========================== min-width 850px
    .cardsPage {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center;

      .cardsPage__title {
        position: relative;
        display: grid;
        align-items: center;
        height: 170px;
        width: 100%;
        background: rgb(226, 224, 224);
        border-bottom: 2px solid gray;
        .styleInstruction {
          text-decoration: none;
          color: maroon !important;
          a {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: center;
            justify-items: center;
            align-content: center;
            align-items: center;
            color: rgb(53, 5, 5);
          }
        }
      }
      .cardsPage__container {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        top: 50px;
        width: 100%;
        align-items: center;
        justify-items: center;

        //==================================== card 1
        .cardsPage__card1 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          width: 100%;
          height: 100%;

          .gradientBg {
            position: absolute;
            z-index: 500;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              158.46deg,
              rgba(0, 189, 231, 0.7) 0%,
              rgba(225, 0, 149, 0.7) 96.88%
            );
          }

          img {
            position: relative;
            z-index: 0;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
          }

          .card1__header {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr 4fr;
            align-items: center;
            z-index: 1000;
            color: white;
          }

          .card1__title {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            justify-content: start;
            z-index: 1000;
            top: 22%;
            padding: 0px 20px;
            font-size: 17px;
            color: white;
          }

          .card1__body {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            text-align: left;
            justify-content: start;
            z-index: 1000;
            top: 40%;
            padding: 0px 20px;
            font-size: 17px;
            color: white;
            .card1__content__ctaButton {
              position: relative;
              top: 25%;
              .ctaButton1 {
                color: white;
                border: 1px solid white;
              }
            }
          }

          .card1__actions {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr 2fr;
            grid-template-columns: 1fr 2fr auto;
            grid-gap: 2vw;
            justify-content: center;
            width: 100%;
            z-index: 1000;
            bottom: 10px;
            color: white;
            .cardType {
              color: #00ffc2;
              border: 1px solid #00ffc2;
            }
            .unclaimedButton {
              color: #fff500;
              border: 1px solid #fff500;
            }
          }
        }
        //==================================== card 1

        //==================================== card 2
        .cardsPage__card2 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          top: 50px;
          width: 100%;
          height: 100%;

          .gradientBg {
            position: absolute;
            z-index: 500;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              158.46deg,
              rgba(0, 189, 231, 0.7) 0%,
              rgba(225, 0, 149, 0.7) 96.88%
            );
          }

          img {
            position: relative;
            z-index: 0;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
          }

          .card2__header {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr 4fr;
            align-items: center;
            z-index: 1000;
            color: white;
          }

          .card2__title {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            justify-content: start;
            top: 22%;
            padding: 0px 20px;
            z-index: 1000;
            padding: 0px 20px;
            font-size: 17px;
            color: white;
          }

          .card2__body {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            text-align: left;
            justify-content: start;
            z-index: 1000;
            padding: 0px 20px;
            top: 40%;
            padding: 0px 20px;
            font-size: 17px;
            color: white;
            .card2__content__ctaButton {
              position: relative;
              top: 25%;
              .ctaBUtton2 {
                color: white;
                border: 1px solid white;
              }
            }
          }

          .card2__actions {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr 2fr auto;
            grid-gap: 2vw;
            justify-content: center;
            width: 100%;
            z-index: 1000;
            bottom: 10px;
            color: white;
            .cardType {
              color: #00ffc2;
              border: 1px solid #00ffc2;
            }
            .unclaimedButton {
              color: #fff500;
              border: 1px solid #fff500;
            }
          }
        }
        //==================================== card 2
        //==================================== card 3
        .cardsPage__card3 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          top: 100px;
          width: 100%;
          height: 100%;
          background: #f9f9f9;

          img {
            position: relative;
            z-index: 0;
            top: 0px;
            left: 0px;
            width: 100%;
          }

          .card3__actions {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr 2fr auto;
            grid-gap: 2vw;
            justify-content: center;
            width: 100%;
            z-index: 1000;
            top: 90%;
            color: white;
            .cardType {
              color: #00ffc2;
              border: 1px solid #00ffc2;
            }
            .unclaimedButton {
              color: #c4c4c4;
              border: 1px solid #c4c4c4;
            }
          }

          .card3__title {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            justify-content: start;
            z-index: 1000;
            padding: 0px 20px;
            top: 100%;
            font-size: 17px;
            color: #595959;
          }
          .card3__body {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            text-align: left;
            justify-content: start;
            z-index: 1000;
            top: 120%;
            padding: 0px 20px;
            font-size: 17px;
            color: #8b8b8b;
          }
          .card3__footer {
            position: relative;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 5vw;
            width: 100%;
            height: 100%;
            top: 250px;

            .card3__content__ctaButton {
              position: relative;
              display: grid;
              grid-template-columns: 1fr;
            }
            .card3__header {
              position: relative;
              display: flex;
              flex-direction: row;
              align-items: center;
              z-index: 1000;
              color: #595959;
            }
          }
        }
        //==================================== card 3

        //==================================== card 4
        .cardsPage__card4 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          top: 450px;
          width: 100%;
          height: 100%;
          background: #fbfbfb;

          img {
            position: relative;
            z-index: 0;
            top: 0px;
            left: 0px;
            width: 100%;
          }

          .card4__header {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr 4fr;
            top: 0px;
            align-items: center;
            z-index: 1000;
            color: white;
          }

          .card4__actions {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr 2fr auto;
            grid-gap: 2vw;
            justify-content: center;
            width: 100%;
            z-index: 1000;
            top: 66%;
            color: white;
            .cardType {
              color: #00ffc2;
              border: 1px solid #00ffc2;
            }
            .unclaimedButton {
              color: #c4c4c4;
              border: 1px solid #c4c4c4;
            }
          }

          .card4__title {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            justify-self: start;
            justify-content: start;
            z-index: 1000;
            top: 10px;
            color: #595959;
          }

          .card4__body {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            text-align: left;
            justify-content: start;
            z-index: 1000;
            padding: 0px 20px;
            top: 0px;
            color: #8b8b8b;
            .card4__content__ctaButton {
              position: relative;
              display: grid;
              grid-template-columns: 1fr 1fr;
              top: 0px;
              margin-bottom: 50px;
              .ctaButton4 {
                position: relative;
                display: grid;
                grid-template-columns: 1fr;
                top: 40px;
                color: #595959;
                border: 1px solid #595959;
              }
            }
          }
        }
        //==================================== card 4
      }
    }
  } //=========================== min-width 850px

  @media (min-width: 1150px) {
    //=========================== min-width 1150px
    .cardsPage {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center;

      .cardsPage__title {
        position: relative;
        display: grid;
        align-items: center;
        height: 170px;
        width: 100%;
        background: rgb(226, 224, 224);
        border-bottom: 2px solid gray;
        .styleInstruction {
          text-decoration: none;
          color: maroon !important;
          a {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: center;
            justify-items: center;
            align-content: center;
            align-items: center;
            color: rgb(53, 5, 5);
          }
        }
      }
      .cardsPage__container {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 2vw;
        top: 50px;
        width: 100%;
        align-items: center;
        justify-items: center;

        //==================================== card 1
        .cardsPage__card1 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          width: 100%;
          height: 100%;

          .gradientBg {
            position: absolute;
            z-index: 500;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              158.46deg,
              rgba(0, 189, 231, 0.7) 0%,
              rgba(225, 0, 149, 0.7) 96.88%
            );
          }

          img {
            position: relative;
            z-index: 0;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
          }

          .card1__header {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr 4fr;
            align-items: center;
            z-index: 1000;
            color: white;
          }

          .card1__title {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            justify-content: start;
            z-index: 1000;
            top: 22%;
            padding: 0px 20px;
            font-size: 17px;
            color: white;
          }

          .card1__body {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            text-align: left;
            justify-content: start;
            z-index: 1000;
            top: 40%;
            padding: 0px 20px;
            font-size: 17px;
            color: white;
            .card1__content__ctaButton {
              position: relative;
              top: 25%;
              .ctaButton1 {
                color: white;
                border: 1px solid white;
              }
            }
          }

          .card1__actions {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr 2fr;
            grid-template-columns: 1fr 2fr auto;
            grid-gap: 2vw;
            justify-content: center;
            width: 100%;
            z-index: 1000;
            bottom: 10px;
            color: white;
            .cardType {
              color: #00ffc2;
              border: 1px solid #00ffc2;
            }
            .unclaimedButton {
              color: #fff500;
              border: 1px solid #fff500;
            }
          }
        }
        //==================================== card 1

        //==================================== card 2
        .cardsPage__card2 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          top: 0px;
          width: 100%;
          height: 100%;

          .gradientBg {
            position: absolute;
            z-index: 500;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              158.46deg,
              rgba(0, 189, 231, 0.7) 0%,
              rgba(225, 0, 149, 0.7) 96.88%
            );
          }

          img {
            position: relative;
            z-index: 0;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
          }

          .card2__header {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr 4fr;
            align-items: center;
            z-index: 1000;
            color: white;
          }

          .card2__title {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            justify-content: start;
            top: 22%;
            padding: 0px 20px;
            z-index: 1000;
            padding: 0px 20px;
            font-size: 17px;
            color: white;
          }

          .card2__body {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            text-align: left;
            justify-content: start;
            z-index: 1000;
            padding: 0px 20px;
            top: 40%;
            padding: 0px 20px;
            font-size: 17px;
            color: white;
            .card2__content__ctaButton {
              position: relative;
              top: 25%;
              .ctaBUtton2 {
                color: white;
                border: 1px solid white;
              }
            }
          }

          .card2__actions {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr 2fr auto;
            grid-gap: 2vw;
            justify-content: center;
            width: 100%;
            z-index: 1000;
            bottom: 10px;
            color: white;
            .cardType {
              color: #00ffc2;
              border: 1px solid #00ffc2;
            }
            .unclaimedButton {
              color: #fff500;
              border: 1px solid #fff500;
            }
          }
        }
        //==================================== card 2
        //==================================== card 3
        .cardsPage__card3 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          top: 0px;
          width: 100%;
          height: auto;
          background: #f9f9f9;

          img {
            position: relative;
            z-index: 0;
            top: 0px;
            left: 0px;
            width: 100%;
          }

          .card3__actions {
            position: relative;
            display: grid;
            grid-template-columns: 1fr 2fr auto;
            grid-gap: 2vw;
            justify-content: center;
            width: 100%;
            z-index: 1000;
            top: 50%;
            color: white;
            .cardType {
              color: #00ffc2;
              border: 1px solid #00ffc2;
            }
            .unclaimedButton {
              color: #c4c4c4;
              border: 1px solid #c4c4c4;
            }
          }

          .card3__title {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            justify-self: start;
            justify-content: start;
            z-index: 1000;
            padding: 0px 20px;
            top: 40%;
            font-size: 17px;
            color: #595959;
          }
          .card3__body {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            text-align: left;
            justify-content: start;
            z-index: 1000;
            top: 20%;
            padding: 0px 20px;
            font-size: 17px;
            color: #8b8b8b;
          }
          .card3__footer {
            position: relative;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 2vw;
            width: 100%;
            top: 50px;

            .card3__content__ctaButton {
              position: relative;
              .ctaButton3 {
                position: relative;
              }
            }

            .card3__header {
              position: relative;
              display: flex;
              flex-direction: row;
              align-items: center;
              z-index: 1000;
              color: #595959;
            }
          }
        }
        //==================================== card 3

        //==================================== card 4
        .cardsPage__card4 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          top: 0px;
          width: 100%;
          height: auto;
          background: #fbfbfb;

          img {
            position: relative;
            z-index: 0;
            top: 0px;
            left: 0px;
            width: 100%;
          }

          .card4__header {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr 4fr;
            top: 0px;
            align-items: center;
            z-index: 1000;
            color: white;
          }

          .card4__actions {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr 2fr auto;
            grid-gap: 2vw;
            justify-content: center;
            width: 100%;
            z-index: 1000;
            top: 58%;
            color: white;
            .cardType {
              color: #00ffc2;
              border: 1px solid #00ffc2;
            }
            .unclaimedButton {
              color: #c4c4c4;
              border: 1px solid #c4c4c4;
            }
          }

          .card4__title {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            justify-self: start;
            justify-content: start;
            z-index: 1000;
            top: 20px;
            color: #595959;
          }

          .card4__body {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            text-align: left;
            justify-content: start;
            z-index: 1000;
            top: 10px;
            color: #8b8b8b;
            .card4__content__ctaButton {
              position: relative;
              display: grid;
              grid-template-columns: 1fr 1fr;
              top: 0px;
              margin-bottom: 50px;
              .ctaButton4 {
                position: relative;
                display: grid;
                grid-template-columns: 1fr;
                top: 40px;
                color: #595959;
                border: 1px solid #595959;
              }
            }
          }
        }
        //==================================== card 4
      }
    }
  } //=========================== min-width 1150px

  @media (min-width: 1366px) {
    //=========================== min-width 1366px
    .cardsPage {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center;

      .cardsPage__title {
        position: relative;
        display: grid;
        align-items: center;
        height: 170px;
        width: 100%;
        background: rgb(226, 224, 224);
        border-bottom: 2px solid gray;
        .styleInstruction {
          text-decoration: none;
          color: maroon !important;
          a {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: center;
            justify-items: center;
            align-content: center;
            align-items: center;
            color: rgb(53, 5, 5);
          }
        }
      }
      .cardsPage__container {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 2vw;
        top: 50px;
        width: 100%;
        align-items: center;
        justify-items: center;

        //==================================== card 1
        .cardsPage__card1 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          width: 382px;
          height: 608px;

          .gradientBg {
            position: absolute;
            z-index: 500;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              158.46deg,
              rgba(0, 189, 231, 0.7) 0%,
              rgba(225, 0, 149, 0.7) 96.88%
            );
          }

          img {
            position: relative;
            z-index: 0;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
          }

          .card1__header {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr 4fr;
            top: 10px;
            left: 15px;
            align-items: center;
            z-index: 1000;
            color: white;
          }

          .card1__title {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            justify-content: start;
            z-index: 1000;
            top: 22%;
            padding: 0px 20px;
            font-family: 'Open Sans';
            font-size: 17px;
            font-weight: 600;
            line-height: 23.15px;
            color: white;
          }

          .card1__body {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            text-align: left;
            justify-content: start;
            z-index: 1000;
            top: 35%;
            padding: 0px 20px;
            font-family: 'Open Sans';
            font-size: 17px;
            font-weight: 400;
            line-height: 23.15px;
            color: white;
            .card1__content__ctaButton {
              position: relative;
              top: 25%;
              .ctaButton1 {
                color: white;
                border: 1px solid white;
              }
            }
          }

          .card1__actions {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr 2fr;
            justify-items: start;
            width: 100%;
            z-index: 1000;
            left: 10px;
            bottom: 10px;
            color: white;
            .cardType {
              color: #00ffc2;
              border: 1px solid #00ffc2;
            }
            .unclaimedButton {
              color: #fff500;
              border: 1px solid #fff500;
            }
          }
        }
        //==================================== card 1

        //==================================== card 2
        .cardsPage__card2 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          width: 382px;
          height: 432px;

          .gradientBg {
            position: absolute;
            z-index: 500;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              158.46deg,
              rgba(0, 189, 231, 0.7) 0%,
              rgba(225, 0, 149, 0.7) 96.88%
            );
          }

          img {
            position: relative;
            z-index: 0;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
          }

          .card2__header {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr 4fr;
            top: 10px;
            left: 15px;
            align-items: center;
            z-index: 1000;
            color: white;
          }

          .card2__title {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            justify-content: start;
            z-index: 1000;
            top: 10%;
            padding: 0px 20px;
            font-family: 'Open Sans';
            font-size: 28px;
            font-weight: 600;
            line-height: 38.13px;
            color: white;
          }

          .card2__body {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            text-align: left;
            justify-content: start;
            z-index: 1000;
            top: 45%;
            padding: 0px 20px;
            font-family: 'Open Sans';
            font-size: 17px;
            font-weight: 400;
            line-height: 23.15px;
            color: white;
            .card2__content__ctaButton {
              position: relative;
              top: 25%;
              .ctaButton2 {
                color: white;
                border: 1px solid white;
              }
            }
          }

          .card2__actions {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr 2fr;
            justify-items: start;
            width: 100%;
            z-index: 1000;
            left: 10px;
            bottom: 10px;
            color: white;
            .cardType {
              color: #00ffc2;
              border: 1px solid #00ffc2;
            }
            .unclaimedButton {
              color: #fff500;
              border: 1px solid #fff500;
            }
          }
        }
        //==================================== card 2
        //==================================== card 3
        .cardsPage__card3 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          top: 0px;
          width: 382px;
          height: 296px;
          background: #f9f9f9;

          img {
            position: relative;
            z-index: 0;
            top: 0px;
            left: 0px;
            width: 100%;
          }

          .card3__actions {
            position: relative;
            display: grid;
            grid-template-columns: 1fr 2fr;
            justify-content: start;
            width: 100%;
            z-index: 1000;
            top: 50%;
            color: white;
            .cardType {
              color: #00ffc2;
              border: 1px solid #00ffc2;
            }
            .unclaimedButton {
              color: #c4c4c4;
              border: 1px solid #c4c4c4;
            }
          }

          .card3__title {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            justify-self: start;
            justify-content: start;
            z-index: 1000;
            top: 40%;
            font-size: 17px;
            color: #595959;
          }
          .card3__body {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            text-align: left;
            justify-content: start;
            z-index: 1000;
            top: 20%;
            font-size: 17px;
            color: #8b8b8b;
          }
          .card3__footer {
            position: relative;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 2vw;
            width: 100%;
            top: 50px;

            .card3__content__ctaButton {
              position: relative;
              .ctaButton3 {
                position: relative;
              }
            }

            .card3__header {
              position: relative;
              display: flex;
              flex-direction: row;
              align-items: center;
              z-index: 1000;
              color: #595959;
            }
          }
        }
        //==================================== card 3

        //==================================== card 4
        .cardsPage__card4 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          top: 0px;
          width: 382px;
          height: 609px;
          background: #fbfbfb;

          img {
            position: relative;
            z-index: 0;
            top: 0px;
            left: 0px;
            width: 100%;
          }

          .card4__header {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr 4fr;
            top: 0px;
            left: 15px;
            align-items: center;
            z-index: 1000;
            color: white;
          }

          .card4__actions {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr 2fr;
            justify-content: start;
            width: 100%;
            z-index: 1000;
            top: 55%;
            color: white;
            .cardType {
              color: #00ffc2;
              border: 1px solid #00ffc2;
            }
            .unclaimedButton {
              color: white;
              border: 1px solid white;
            }
          }

          .card4__title {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            justify-self: start;
            justify-content: start;
            z-index: 1000;
            top: 20px;
            color: #595959;
          }

          .card4__body {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            text-align: left;
            justify-content: start;
            z-index: 1000;
            top: 10px;
            color: #8b8b8b;
            .card4__content__ctaButton {
              position: relative;
              display: grid;
              grid-template-columns: 1fr 1fr;
              top: 0px;
              margin-bottom: 50px;
              .ctaButton4 {
                position: relative;
                display: grid;
                grid-template-columns: 1fr;
                top: 40px;
                color: #595959;
                border: 1px solid #595959;
              }
            }
          }
        }
        //==================================== card 4
      }
    }
  }
} //=========================== min-width 1366px
