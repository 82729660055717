@media screen and (max-width: 1120px) {
  .typographyPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;

    .typographyPage__titleInput {
      position: relative;
      height: 150px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        color: maroon;
      }
    }

    .typographyPage__fontsUsed {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;

      .typographyPage__crimsonText {
        p {
          font-family: 'Crimson Text';
          font-style: normal;
          font-weight: 400;
          color: #7d7d7d;
        }

        .typographyPage__crimsonText__container {
          position: relative;
          border: 5px solid gray;

          .ni2heading {
            padding-bottom: 15px;
            border-bottom: 1px solid gray;
            h1 {
              font-family: 'Crimson Text' !important;
              font-size: 52px !important;
              font-style: normal;
              font-weight: 400;
              line-height: 74.4px !important;
            }
          }

          .ni2h1 {
            padding-bottom: 15px;
            border-bottom: 1px solid gray;
            h1 {
              font-family: 'Crimson Text' !important;
              font-size: 52px !important;
              font-style: normal;
              font-weight: 400;
              line-height: 62px !important;
            }
          }

          .ni2h2 {
            padding-bottom: 15px;
            border-bottom: 1px solid gray;
            h2 {
              font-family: 'Crimson Text' !important;
              font-size: 40px !important;
              font-style: normal;
              font-weight: 600;
              line-height: 48px !important;
            }
          }

          .ni2h3 {
            padding-bottom: 15px;
            border-bottom: 1px solid gray;
            h3 {
              font-family: 'Crimson Text' !important;
              font-size: 32px !important;
              font-style: normal;
              font-weight: 400;
              line-height: 38px !important;
            }
          }
        }
      }

      .typographyPage__openSans {
        p {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          color: #7d7d7d;
        }

        .typographyPage__openSans__container {
          position: relative;
          border: 5px solid gray;
          top: 15px;

          .ni2bodyHeading {
            padding-bottom: 15px;
            border-bottom: 1px solid gray;
            h4 {
              font-family: 'Open Sans' !important;
              font-size: 26px !important;
              font-style: normal;
              font-weight: 400;
              line-height: 46.5px !important;
            }
          }

          .ni2h4 {
            padding-bottom: 15px;
            border-bottom: 1px solid gray;
            h4 {
              font-family: 'Open Sans' !important;
              font-size: 26px !important;
              font-style: normal;
              font-weight: 600;
              line-height: 31px !important;
            }
          }

          .ni2h5 {
            padding-bottom: 15px;
            border-bottom: 1px solid gray;
            h5 {
              font-family: 'Open Sans' !important;
              font-size: 21px !important;
              font-style: normal;
              font-weight: 400;
              line-height: 25px !important;
            }
          }

          .ni2h6 {
            padding-bottom: 15px;
            border-bottom: 1px solid gray;
            h6 {
              font-family: 'Open Sans' !important;
              font-size: 17px !important;
              font-style: normal;
              font-weight: 600;
              line-height: 20px !important;
            }
          }

          .ni2body {
            padding-bottom: 15px;
            border-bottom: 1px solid gray;
            h6 {
              font-family: 'Open Sans' !important;
              font-size: 17px !important;
              font-style: normal;
              font-weight: 400;
              line-height: 25px !important;
            }
          }

          .ni2subHeader {
            padding-bottom: 15px;
            border-bottom: 1px solid gray;
            h6 {
              font-family: 'Open Sans' !important;
              font-size: 14px !important;
              font-style: normal;
              font-weight: 400;
              line-height: 17px !important;
            }
          }

          .ni2button {
            padding-bottom: 15px;
            border-bottom: 1px solid gray;
            h6 {
              font-family: 'Open Sans' !important;
              font-size: 14px !important;
              font-style: normal;
              font-weight: 400;
              line-height: 17px !important;
            }
          }

          .ni2caption1 {
            padding-bottom: 15px;
            border-bottom: 1px solid gray;
            h6 {
              font-family: 'Open Sans' !important;
              font-size: 11px !important;
              font-style: normal;
              font-weight: 400;
              line-height: 13px !important;
            }
          }

          .ni2overline {
            padding-bottom: 15px;
            border-bottom: 1px solid gray;
            h6 {
              font-family: 'Open Sans' !important;
              font-size: 9px !important;
              font-style: normal;
              font-weight: 400;
              line-height: 11px !important;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1120px) {
  .typographyPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;

    .typographyPage__titleInput {
      position: relative;
      height: 150px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        color: maroon;
      }
    }

    .typographyPage__fontsUsed {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;

      .typographyPage__crimsonText {
        p {
          font-family: 'Crimson Text';
          font-style: normal;
          font-weight: 400;
          color: #7d7d7d;
        }

        .typographyPage__crimsonText__container {
          position: relative;
          border: 5px solid gray;

          .ni2heading {
            padding-bottom: 15px;
            border-bottom: 1px solid gray;
            h1 {
              font-family: 'Crimson Text' !important;
              font-size: 52px !important;
              font-style: normal;
              font-weight: 400;
              line-height: 74.4px !important;
            }
          }

          .ni2h1 {
            padding-bottom: 15px;
            border-bottom: 1px solid gray;
            h1 {
              font-family: 'Crimson Text' !important;
              font-size: 52px !important;
              font-style: normal;
              font-weight: 400;
              line-height: 62px !important;
            }
          }

          .ni2h2 {
            padding-bottom: 15px;
            border-bottom: 1px solid gray;
            h2 {
              font-family: 'Crimson Text' !important;
              font-size: 40px !important;
              font-style: normal;
              font-weight: 600;
              line-height: 48px !important;
            }
          }

          .ni2h3 {
            padding-bottom: 15px;
            border-bottom: 1px solid gray;
            h3 {
              font-family: 'Crimson Text' !important;
              font-size: 32px !important;
              font-style: normal;
              font-weight: 400;
              line-height: 38px !important;
            }
          }
        }
      }

      .typographyPage__openSans {
        p {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          color: #7d7d7d;
        }

        .typographyPage__openSans__container {
          position: relative;
          border: 5px solid gray;

          .ni2bodyHeading {
            padding-bottom: 15px;
            border-bottom: 1px solid gray;
            h4 {
              font-family: 'Open Sans' !important;
              font-size: 26px !important;
              font-style: normal;
              font-weight: 400;
              line-height: 46.5px !important;
            }
          }

          .ni2h4 {
            padding-bottom: 15px;
            border-bottom: 1px solid gray;
            h4 {
              font-family: 'Open Sans' !important;
              font-size: 26px !important;
              font-style: normal;
              font-weight: 600;
              line-height: 31px !important;
            }
          }

          .ni2h5 {
            padding-bottom: 15px;
            border-bottom: 1px solid gray;
            h5 {
              font-family: 'Open Sans' !important;
              font-size: 21px !important;
              font-style: normal;
              font-weight: 400;
              line-height: 25px !important;
            }
          }

          .ni2h6 {
            padding-bottom: 15px;
            border-bottom: 1px solid gray;
            h6 {
              font-family: 'Open Sans' !important;
              font-size: 17px !important;
              font-style: normal;
              font-weight: 600;
              line-height: 20px !important;
            }
          }

          .ni2body {
            padding-bottom: 15px;
            border-bottom: 1px solid gray;
            h6 {
              font-family: 'Open Sans' !important;
              font-size: 17px !important;
              font-style: normal;
              font-weight: 400;
              line-height: 25px !important;
            }
          }

          .ni2subHeader {
            padding-bottom: 15px;
            border-bottom: 1px solid gray;
            h6 {
              font-family: 'Open Sans' !important;
              font-size: 14px !important;
              font-style: normal;
              font-weight: 400;
              line-height: 17px !important;
            }
          }

          .ni2button {
            padding-bottom: 15px;
            border-bottom: 1px solid gray;
            h6 {
              font-family: 'Open Sans' !important;
              font-size: 14px !important;
              font-style: normal;
              font-weight: 400;
              line-height: 17px !important;
            }
          }

          .ni2caption1 {
            padding-bottom: 15px;
            border-bottom: 1px solid gray;
            h6 {
              font-family: 'Open Sans' !important;
              font-size: 11px !important;
              font-style: normal;
              font-weight: 400;
              line-height: 13px !important;
            }
          }

          .ni2overline {
            padding-bottom: 15px;
            border-bottom: 1px solid gray;
            h6 {
              font-family: 'Open Sans' !important;
              font-size: 9px !important;
              font-style: normal;
              font-weight: 400;
              line-height: 11px !important;
            }
          }
        }
      }
    }
  }
}
