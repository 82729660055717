@media screen and (max-width: 1112px) {
  .bgColorPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .bgColorPage__title {
      position: relative;
      height: 170px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        color: maroon;
      }
    }

    .bgColorPage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      top: 25px;

      .bgColorPage__row1 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;

        .bgColorPage__row1col1 {
          .bgColorPage__box1 {
            height: 300px;
            width: 300px;
            background: #ffffff;
            border: 1px solid gray;
            h4 {
              color: #ffffff;
              background: black;
            }
          }
        }
        .bgColorPage__row1col2 {
          .bgColorPage__box2 {
            height: 300px;
            width: 300px;
            background: #e4e4e4;
            border: 1px solid gray;
          }
        }
      }

      .bgColorPage__row2 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;

        .bgColorPage__row2col1 {
          .bgColorPage__box3 {
            height: 300px;
            width: 300px;
            background: #595959;
          }
        }
        .bgColorPage__row2col2 {
          .bgColorPage__box4 {
            height: 300px;
            width: 300px;
            background: #1d1d1d;
          }
        }
      }
    }
  }
}

@media (min-width: 1112px) {
  .bgColorPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .bgColorPage__title {
      position: relative;
      height: 170px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        color: maroon;
      }
    }

    .bgColorPage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      top: 25px;

      .bgColorPage__row1 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20vw;
        justify-items: center;

        .bgColorPage__row1col1 {
          .bgColorPage__box1 {
            height: 300px;
            width: 300px;
            background: #ffffff;
            border: 1px solid gray;
            h4 {
              color: #ffffff;
              background: black;
            }
          }
        }
        .bgColorPage__row1col2 {
          .bgColorPage__box2 {
            height: 300px;
            width: 300px;
            background: #e4e4e4;
            border: 1px solid gray;
          }
        }
      }

      .bgColorPage__row2 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20vw;
        justify-items: center;

        .bgColorPage__row2col1 {
          .bgColorPage__box3 {
            height: 300px;
            width: 300px;
            background: #595959;
          }
        }
        .bgColorPage__row2col2 {
          .bgColorPage__box4 {
            height: 300px;
            width: 300px;
            background: #1d1d1d;
          }
        }
      }
    }
  }
}
