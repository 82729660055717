.appHeader {
  background: #f8f8f8;
  padding: 54px 16px 11px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid #C4C4C4;
}

.appName {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  display: flex;
  position: relative;
  color: #595959;
}

.menuIcon {
  color: #595959;
}

.headerBottomSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 16px;
  background-color: #fff;
  a {
    display: flex;
    align-items: center;
    svg {
      padding-right: 5px;
    }
  }
}

.appLogo {
  width: auto;
  max-width: 175px;
  height: 45px;
}