@media screen and (max-width: 916px) {
  .labelColorPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .labelColorPage__title {
      position: relative;
      height: 170px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        color: maroon;
      }
    }

    .labelColorPage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      top: 25px;

      .labelColorPage__row1 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;

        .labelColorPage__row1col1 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
          .labelColorPage__box1 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            width: 300px;
            background: #34c759;
            color: white;
            border: 1px solid gray;
          }
        }

        .labelColorPage__row1col2 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          top: 25px;
          justify-items: center;
          .labelColorPage__box2 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            width: 300px;
            background: #5856d6;
            color: white;
            border: 1px solid gray;
          }
        }

        .labelColorPage__row1col3 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          top: 50px;
          justify-items: center;
          .labelColorPage__box3 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            width: 300px;
            background: #ff9500;
            color: white;
            border: 1px solid gray;
          }
        }

        .labelColorPage__row1col4 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          top: 75px;
          justify-items: center;
          .labelColorPage__box4 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            width: 300px;
            background: #ff375f;
            color: white;
            border: 1px solid gray;
          }
        }
      }

      .labelColorPage__row2 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;

        .labelColorPage__row2col1 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          top: 100px;
          justify-items: center;
          .labelColorPage__box5 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            width: 300px;
            background: #af52de;
            color: white;
          }
        }

        .labelColorPage__row2col2 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          top: 125px;
          justify-items: center;
          .labelColorPage__box6 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            width: 300px;
            background: #ff3b30;
            color: white;
          }
        }

        .labelColorPage__row2col3 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          top: 150px;
          justify-items: center;
          .labelColorPage__box7 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            width: 300px;
            background: #5ac8fa;
            color: white;
          }
        }

        .labelColorPage__row2col4 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          top: 175px;
          justify-items: center;
          .labelColorPage__box8 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            width: 300px;
            background: #ffcc00;
            color: white;
          }
        }
      }
    }
  }
}

@media (min-width: 916px) {
  .labelColorPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .labelColorPage__title {
      position: relative;
      height: 170px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        color: maroon;
      }
    }

    .labelColorPage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      top: 25px;

      .labelColorPage__row1 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1em;
        justify-items: center;

        .labelColorPage__row1col1 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
          .labelColorPage__box1 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            width: 300px;
            background: #34c759;
            color: white;
            border: 1px solid gray;
          }
        }

        .labelColorPage__row1col2 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
          .labelColorPage__box2 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            width: 300px;
            background: #5856d6;
            color: white;
            border: 1px solid gray;
          }
        }

        .labelColorPage__row1col3 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
          .labelColorPage__box3 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            width: 300px;
            background: #ff9500;
            color: white;
            border: 1px solid gray;
          }
        }

        .labelColorPage__row1col4 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
          .labelColorPage__box4 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            width: 300px;
            background: #ff375f;
            color: white;
            border: 1px solid gray;
          }
        }
      }

      .labelColorPage__row2 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        top: 25px;
        justify-items: center;

        .labelColorPage__row2col1 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
          .labelColorPage__box5 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            width: 300px;
            background: #af52de;
            color: white;
          }
        }

        .labelColorPage__row2col2 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
          .labelColorPage__box6 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            width: 300px;
            background: #ff3b30;
            color: white;
          }
        }

        .labelColorPage__row2col3 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          top: 25px;
          justify-items: center;
          .labelColorPage__box7 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            width: 300px;
            background: #5ac8fa;
            color: white;
          }
        }

        .labelColorPage__row2col4 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          top: 25px;
          justify-items: center;
          .labelColorPage__box8 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            width: 300px;
            background: #ffcc00;
            color: white;
          }
        }
      }
    }
  }
}

@media (min-width: 1526px) {
  .labelColorPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .labelColorPage__title {
      position: relative;
      height: 170px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        color: maroon;
      }
    }

    .labelColorPage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      top: 25px;

      .labelColorPage__row1 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 1em;
        justify-items: center;

        .labelColorPage__row1col1 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
          .labelColorPage__box1 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            width: 300px;
            background: #34c759;
            color: white;
            border: 1px solid gray;
          }
        }

        .labelColorPage__row1col2 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
          .labelColorPage__box2 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            width: 300px;
            background: #5856d6;
            color: white;
            border: 1px solid gray;
          }
        }

        .labelColorPage__row1col3 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
          .labelColorPage__box3 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            width: 300px;
            background: #ff9500;
            color: white;
            border: 1px solid gray;
          }
        }

        .labelColorPage__row1col4 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
          .labelColorPage__box4 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            width: 300px;
            background: #ff375f;
            color: white;
            border: 1px solid gray;
          }
        }
      }

      .labelColorPage__row2 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        top: 50px;
        justify-items: center;

        .labelColorPage__row2col1 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
          .labelColorPage__box5 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            width: 300px;
            background: #af52de;
            color: white;
          }
        }

        .labelColorPage__row2col2 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
          .labelColorPage__box6 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            width: 300px;
            background: #ff3b30;
            color: white;
          }
        }

        .labelColorPage__row2col3 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
          .labelColorPage__box7 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            width: 300px;
            background: #5ac8fa;
            color: white;
          }
        }

        .labelColorPage__row2col4 {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
          .labelColorPage__box8 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            width: 300px;
            background: #ffcc00;
            color: white;
          }
        }
      }
    }
  }
}
