.contentContainer {
  // background-color: transparent;
  // background: url('../../../../assets/images/launchpad-bg.png') no-repeat;
  background-size: 100% 100%;
  color: #ffffff;
  overflow: hidden;
  text-align: center;
  position: relative;
}

.contentHead {
  font-family: 'Open Sans';
  text-align: center;
  margin: 8px 8px 0 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #C4C4C4;
}

.contentItem {
  display: flex;
}
