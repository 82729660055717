// .forgot-password-modal {
//   .modal-inner-wrapper {
    .forgot-pw-form-wrapper {
      .desc {
        padding: 32px;
        color: #1F58C1;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 32px;
      }

      .forgot-pw-title {
        color: #595959;
        font-size: 17px;
        line-height: 20px;
        text-align: left;
        padding: 0 16px 24px 16px;
        font-weight: 600;
        a {
          display: flex;
          align-items: center;
          font-size: 17px;
          line-height: 20px;
        }
      }

      .form-row {
        padding-bottom: 16px;

        button {
          width: 100%;
          img {
            font-size: 18px;
          }
        }
      } //.form-row
    } //.forgot-pw-form-wrapper
  // }

  .success-toaster {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 8px 8px 0 0;
  }
// }
