.listLabel {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #595959;
  padding: 0;
  height: 75px;
  margin: 16px;
  border-radius: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 24px rgba(0, 0, 0, 0.064);;
  div {
    margin: 24px;
  }
}

.labelContainer {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
  width: 80%;
}

.menuUser {
  background-color: #FFF;
  display: flex;
  // height: 122px;
  padding-top: 24px;
}

.navWrapper {
  background-color: #FFF;
}

.labelName {
  // align-items: center;
  display: flex;
  height: 87px;
  margin-left: 10px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 25px;
  color: #595959;
}

.btnLogoutLabel {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  margin-left: 12px;
  color: #595959;
}

.logoutArea {
  margin: '0 16px';
  text-align: 'center';
}
