.premiumContainer {
  height: 48px;
  background-color: #276EF1;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  line-height: 16px;
  .premiumCollapse {
    position: absolute;
    right: 10px;
    font-size: 16px;
    cursor: pointer;
  }
}
.collapseContainer {
  height: 48px;
  .collapse {
    background-color: #E4E4E4;
    color: #595959;
    position: absolute;
    right: 0;
    top: 5px;
    height: 40px;
    display: flex;
    align-items: center;
    box-shadow: 0px 2.4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 80px 0px 0px 80px;
    justify-content: center;
    padding-left: 15px;
    padding-right: 5px;
  }
}