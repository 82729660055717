.toaster-wrapper {
  background: #276ef1;
  text-align: center;
  padding: 14px;
  color: white;

  .toaster-inner {
    position: relative;
    margin: 0 50px;

    .toaster-close-btn {
      position: absolute;
      padding: 10px;
      right: -35px;
      top: 50%;
      transform: translateY(-50%);
      background-color: white;

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}
