.cardPageWrapper {
  // background-color: #e4e4e4;
  // padding: 10px 0px;
}

.cardDetails {
  padding: 16px;
  text-align: left;
  min-height: 200px;
  // max-height: 262px;

  h5 {
    padding: 0px;
    margin: 0;
    font-family: 'Open Sans';
    font-style: normal;
    line-height: 31px;
    font-size: 12px;
    line-height: 17px;
    color: #989898;
  }

  .titleContainer {
    padding: 10px 0px 0px;
    font-style: normal;
    font-family: 'Open Sans';
    line-height: 25px;
    text-transform: uppercase;
    font-size: 24px;
    line-height: 32px;
    /* or 123% */

    display: flex;
    align-items: center;
    text-transform: uppercase;

    color: #595959;
    // max-width: 250px;

    .title {
      margin: 0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  p {
    font-size: 11px;
    line-height: 16px;
    color: #989898;
  }
}

.cardSubWrapper {
  padding: 0 20px 20px;
}

.cardHurdleCount {
  background: #689af5;
  border-radius: 15px;
  color: white;
  width: 60%;
  padding: 7px;
  font-size: 11px;
  line-height: 11px;
  align-items: center;
  text-align: center;
  font-family: 'Open Sans';
  font-weight: bold;
}

.cardOverflow {
  height: auto;
  // width: 90%;
  h5 {
    font-family: 'Open Sans';
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    span {
      cursor: pointer;
      span {
        color: #276ef1;
      }
      #more {
        display: none;
        span {
          color: #276ef1;
        }
      }
    }
    #more {
      display: none;
    }
  }
}

.cardSection {
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
}

.cardCrumb {
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 11px;

  display: flex;
  align-items: center;

  color: #ffffff;
  padding-left: 20px;
}

.cardDay {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: #595959;
}

.cardAvatar {
  font-family: 'Open Sans';
  background-color: #595959;
  box-sizing: border-box;
  font-size: 17px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
}
