.link {
  display: inline-block;
  font-size: 14px;
  line-height: 21px;
  * {
    vertical-align: middle;
  }
}

.backLink {
  color: #595959;
  svg {
    padding-right: 5px;
  }
}

.nextLink {
  color: #276EF1 !important;
  svg {
    padding-left: 5px;
  }
}
