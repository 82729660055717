.zonePageWrapper {
  background-color: #fff;
  padding: 0px 0px 10px 0;
}

.zoneDetails {
  padding: 16px;
  text-align: left;
  // height: 165px;
  position: relative;

  h3 {
    padding: 0px 0px 0px;
    margin: 0;
    font-family: 'Open Sans';
    font-weight: 700;
    font-style: normal;
    font-size: 17px;
    line-height: 25px;
    text-transform: uppercase;
  }

  p {
    font-family: 'Open Sans';
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    line-height: 16px;
  }
}

.zoneSubWrapper {
  padding: 0 20px 20px;
}

.zoneHurdleCount {
  border-radius: 15px;
  color: white;
  width: 60%;
  // padding: 6px 12px;
  margin-left: -2px;
  font-size: 11px;
  line-height: 11px;
  align-items: center;
  text-align: center;
  font-family: 'Open Sans';
  // font-weight: bold;
}

.zoneOverflow {
  // height: 80px;
  // overflow: auto;
  padding-top: 9px;
}

.zoneSection {
  display: flex;
  justify-content: space-between;
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
}

.scoreSection {
  flex-grow: 1;
  text-align: right;
  padding-right: 10px;
  padding-top: 16px;
  color: #FFF;
}

.zoneLinkWrapper {
  display: flex;
  align-items: center;
  color: white;
  position: absolute;
  bottom: 10px;
  right: 15px;
}

.collapseIcon {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
}