.custom-modal {
  .modal-inner-wrapper {
    background-color: white;
    border-radius: 8px;
    padding: 30px 17px;
    width: calc(100% - 60px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;

    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 40px;

      h2 {
        margin: 0;
        font-weight: bold;
        font-size: 17px;
        line-height: 23px;
        text-transform: uppercase;
        color: #595959;
      }
    }

    .modal-desc {
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 25px;
      color: #595959;
    }
  }
}
