.wrapper {
  width: 100%;
}

.header {
  font-size: 16px;
}

.body {
  overflow-y: scroll;
}
