@media screen and (max-width: 600px) {
  .boxSpacingPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;

    .boxSpacingPage__title {
      position: relative;
      height: 170px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        text-decoration: none;
        color: maroon !important;
        a {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: center;
          justify-items: center;
          align-content: center;
          align-items: center;
          color: rgb(53, 5, 5);
        }
      }
    }

    .boxSpacingPage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      justify-content: center;
      width: 100%;
      top: 50px;
    }
  }
}

@media (min-width: 600px) {
  .boxSpacingPage {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;

    .boxSpacingPage__title {
      position: relative;
      height: 170px;
      width: 100%;
      background: rgb(226, 224, 224);
      border-bottom: 2px solid gray;
      .styleInstruction {
        text-decoration: none;
        color: maroon !important;
        a {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: center;
          justify-items: center;
          align-content: center;
          align-items: center;
          color: rgb(53, 5, 5);
        }
      }
    }

    .boxSpacingPage__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      justify-content: center;
      width: 100%;
      top: 50px;
    }
  }
}
