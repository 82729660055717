.contentContainer {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(13, 13, 13, 0.6) 100%
  );
  color: #ffffff;
  height: 180px;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.contentHead {
  font-family: 'Open Sans';
  height: 180px;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 1001;
}

.contentHead h2 {
  margin-bottom: 0;
  padding-top: 20px;
}

.contentHead p {
  margin-top: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
}

.contentHead img {
  left: 0;
  position: absolute;
  transform: rotate(-180deg);
  width: 100%;
  z-index: -1;

  height: 750px;
  top: -280px;
}

.contentHeadBtn {
  color: #ffffff;
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 14px;
  line-height: 13px;
  margin: 8px 19px;
}

.launchpads {
  background: linear-gradient(180deg, #3e3e3e 0%, #1b1b1b 100%);
  color: #ffffff;
  height: 53px;
  width: 100%;
}

.launchpadsNavIcon {
  display: flex;
  flex-direction: row-reverse;
  margin: 14px auto;
  padding-right: 8px;
  right: 6.67%;
}
