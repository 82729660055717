.horizontalRate {
  border: 1px solid #595959;
  border-radius: 5px;
  color: #595959;
  height: 51px;
  margin: 0px 15px;
  width: 50px;
  p {
    position: relative;
    display: flex;
    left: -20px;
    width: 100px;
    flex-direction: row;
    font-family: "Open Sans";
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    list-style: 19px;
  }
}

.horizontalRateValue {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  text-align: center;
  color: #595959;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radiogroup__input {
  visibility: hidden;
  height: 0;
  width: 0;
  overflow: hidden;
  & + .horizontalRate {
    background-color: #fff;
    .horizontalRateValue {
      color: #595959;
    }
  }
  &:checked + .horizontalRate {
    background-color: #276ef1;
    .horizontalRateValue {
      color: #fff;
    }
  }
}
